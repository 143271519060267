import { FormattedMessage } from 'react-intl'

import localeEn from './en-US'

const messages = {
  en: localeEn
}

export default messages

export const fm = (key, values = {}) => {
  return (
    <FormattedMessage id={key} defaultMessage={localeEn[key]} values={values} />
  )
}
