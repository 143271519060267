import { useCallback, useEffect, useState } from 'react'
import { Card, Row, Col, Form, PageHeader, Table, Tabs, notification } from 'antd'
import { useLocation, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { fm } from '../../../lang'
import {
  createBlock,
  updateBlock,
  deleteBlock,
  resetBlock,
  selectBlock,
  updateBlockLocal
} from '../../../data/actions/blocks'
import { useRecordHook } from '../../../helpers/hooks/useRecordHook'
import { usePush } from '../../../data/actions/routes'
import { showError } from '../../../data/actions/ui'
import { useBreadcrumb } from '../../../helpers/hooks/useBreadcrumb'
import { HtmlForm } from './Form/HtmlForm'
import { CollectionBlock } from './Collection/CollectionBlock'
import { WebsitesCell } from '../../Shared/WebsitesCell'
import { SHOP_URL } from '../../../constants/api'

import { ProductsCompare } from './Compare'
import { EditorBlock } from './EditorBlock'
import { HeaderBlock } from './Header/HeaderBlock'
import { ArticlesBlock } from './Articles'

const pageColumns = [
  {
    title: fm('table.name'),
    key: 'name',
    dataIndex: 'name',
    render: (name, { id }) => <Link to={`/content/pages/${id}`} className="ant-typography">{name}</Link>
  },
  {
    title: fm('table.kind'),
    key: 'kind',
    dataIndex: 'kind',
    width: 100
  },
  {
    title: fm('table.published-at'),
    key: 'publishedAt',
    dataIndex: 'publishedAt',
    width: 200,
    render: (publishedAt) => publishedAt && moment(publishedAt).format('MMMM Do YYYY')
  },
  {
    title: fm('table.websites'),
    key: 'websiteIds',
    dataIndex: 'websiteIds',
    width: 250,
    render: (websiteIds, record) => (
      record.translations.find(({ locale }) => locale === 'en').slug || !record.publishedAt
        ? (
          <WebsitesCell websiteIds={websiteIds}>
            {({ website, name }) => {
              let pageUrl = null
              if (record.publishedAt && moment(record.publishedAt).isBefore(moment())) {
                const websiteTranslation = record.translations.find(({ locale }) => locale === website.locale)
                const defaultTranslation = record.translations.find(({ locale }) => locale === 'en')
                const slug = (websiteTranslation && websiteTranslation.slug) || defaultTranslation.slug

                pageUrl = new URL(`${website.prefix}${slug}`, SHOP_URL)
              } else {
                pageUrl = new URL(`${website.prefix}/_drafts/${record.id}`, SHOP_URL)
              }
              return (<a href={pageUrl} target="_blank" rel="noreferrer">{name}</a>)
            }}
          </WebsitesCell>
          )
        : <WebsitesCell websiteIds={websiteIds} />
    )
  }
]

const initialFormValues = { values: {}, errors: [] }

export const BlockForm = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)
  const push = usePush()
  const location = useLocation()
  const { newRecord, params } = useRecordHook()
  const { id } = params
  const { entry } = useSelector((state) => state.blocks)
  const { entries: pages } = useSelector((state) => state.pages)
  const blockPages = pages.filter(({ components }) => components.find((component) => component.blockableId === id))
  const dispatch = useDispatch()
  const { kind } = entry || {}
  const [formValues, setFormValues] = useState(initialFormValues)
  const [form] = Form.useForm()
  const handleBack = useCallback(() => {
    push('/content/blocks')
  }, [push])
  useEffect(() => {
    if (!isLoading) {
      if (entry === undefined) {
        showError(dispatch, 'record-not-found')
        resetBlock(dispatch)
        handleBack()
      } else {
        const { lockVersion, ...data } = entry
        form.setFieldsValue(data)
        form.setFieldsValue(formValues.values)
        form.setFields(formValues.errors)
      }
    }
  }, [entry, handleBack, dispatch, form, formValues, isLoading])
  const onFinish = useCallback(async () => {
    const values = form.getFieldsValue(true)

    try {
      setFormValues({ values, errors: formValues.errors.map(({ name }) => ({ name, errors: [] })) })

      if (newRecord) {
        const createParams = { ...entry, ...values }
        await createBlock(dispatch, createParams)
        push(`/content/blocks/${createParams.id}`)
        notification.success({ message: 'Block successfully created' })
      } else {
        const updateParams = { ...entry, ...values }
        await updateBlock(dispatch, updateParams)
        notification.success({ message: 'Block successfully updated' })
        selectBlock(dispatch, id)
      }
    } catch (e) {
      const errors = e.action.reason.map(({ path, message }) => (
        {
          name: path,
          errors: [message]
        }
      ))

      setFormValues({ values, errors })
    }
  }, [newRecord, id, dispatch, entry, form, push])
  const deleteRecord = useCallback(() => {
    deleteBlock(dispatch, id, push)
  }, [id, push, dispatch])
  useEffect(() => {
    if (!isLoading && !newRecord) {
      selectBlock(dispatch, id)
    } else if (newRecord) {
      resetBlock(dispatch)
      const query = new URLSearchParams(location.search)
      const queryKind = query.get('kind')
      if (queryKind) {
        updateBlockLocal(dispatch, id, { kind: queryKind })
      }
    }
  }, [isLoading, dispatch, newRecord, id, location])
  const cardTitle = newRecord ? 'new' : 'edit'

  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm(`block.form.${cardTitle}`)}
      subTitle={fm(`block.form.kind.${kind}`)}
      onBack={handleBack}
    >
      <Tabs
        defaultActiveKey="basic"
        items={[{
          key: 'basic',
          label: fm('tab.basic'),
          children: (
            <>
              {kind === 'html' && (
                <HtmlForm form={form} onFinish={onFinish} deleteRecord={deleteRecord} isLoading={isLoading} />
              )}
              {kind === 'collection' && (
                <CollectionBlock form={form} onFinish={onFinish} deleteRecord={deleteRecord} isLoading={isLoading} />
              )}
              {kind === 'compare' && (
                <ProductsCompare form={form} onFinish={onFinish} deleteRecord={deleteRecord} isLoading={isLoading} />
              )}
              {kind === 'editor' && (
                <EditorBlock form={form} onFinish={onFinish} deleteRecord={deleteRecord} isLoading={isLoading} />
              )}
              {kind === 'articles' && (
                <ArticlesBlock form={form} onFinish={onFinish} deleteRecord={deleteRecord} isLoading={isLoading} />
              )}
              {kind === 'header' && (
                <HeaderBlock form={form} onFinish={onFinish} deleteRecord={deleteRecord} isLoading={isLoading} />
              )}
            </>
          )
        }, {
          key: 'pages',
          label: fm('tab.pages'),
          children: (
            <Card size="small" loading={isLoading}>
              <Row>
                <Col xs={24}>
                  <Table
                    dataSource={blockPages}
                    columns={pageColumns}
                    size="small"
                    pagination={false}
                  />
                </Col>
              </Row>
            </Card>
          )
        }]}
      />
    </PageHeader>
  )
}
