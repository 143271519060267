import { Menu } from 'antd'
import { LoginOutlined } from '@ant-design/icons'
import { fm } from '../../../lang'

export const UserMenu = ({ handleClick }) => {
  return (
    <Menu style={{ width: 120 }} onClick={handleClick}>
      <Menu.Item key="account">{fm('user-menu.account')}</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <LoginOutlined />
        {fm('user-menu.logout')}
      </Menu.Item>
    </Menu>
  )
}
