import { Switch } from 'react-router-dom'
import { useSubscription } from '@logux/redux'

import { useAllow } from '../../helpers/hooks/usePermissions'
import { PrivateRoute } from '../PrivateRoute'
import { ArticlesScreen } from '../../screens/Articles/ArticlesScreen'
import { ArticleFormScreen } from '../../screens/Articles/ArticleFormScreen'

export const ArticlesRouter = ({ isAuthenticated }) => {
  const isLoading = useSubscription(['articles'])
  const allow = useAllow()
  return (
    <Switch>
      <PrivateRoute
        allow={allow('can_create_article')}
        path="/content/articles/new"
        exact
        isAuthenticated={isAuthenticated}
        component={ArticleFormScreen}
      />
      <PrivateRoute
        allow={allow('can_edit_article')}
        path="/content/articles/:id"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={ArticleFormScreen}
      />
      <PrivateRoute
        allow={allow('can_show_articles')}
        path="/content/articles"
        exact
        isAuthenticated={isAuthenticated}
        componentProps={{ isLoading }}
        component={ArticlesScreen}
      />
    </Switch>
  )
}
