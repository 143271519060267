import * as types from '../../constants/actionTypes/countriesActionTypes'

const defaultState = {
  entries: []
}

export default function countriesReducer (state = defaultState, action) {
  switch (action.type) {
    case types.COUNTRIES_FETCH:
      return {
        ...state,
        entries: action.data.records
      }
    default:
      return state
  }
}
