import {
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Tabs,
  Button,
  Select,
  Checkbox,
  DatePicker,
  Typography
} from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import { fm } from '../../../lang'
import { useSelector } from 'react-redux'

import { MediaGroupFields } from './MediaGroupFields'
import { emptyVariant } from '../../../data/reducers/productsReducer'
import { getProductColors } from '../../../data/selectors/productColors'

const { Text } = Typography

export const ProductVariantFields = ({ form, product }) => {
  const productColors = useSelector((state) => getProductColors(state, product.id))
  const shops = useSelector((state) => state.shops.entries)
  const countries = useSelector((state) => state.countries.entries)
  const shopCountries = _.groupBy(countries, 'shopId')
  const sageWarehouses = useSelector((state) => state.sageWarehouses.entries)

  return (
    <Form.List name="variants">
      {(fields, { add, remove }) => (
        <Tabs
          type="editable-card"
          tabPosition="left"
          onEdit={(targetKey, action) => {
            if (action === 'add') {
              add({
                ...emptyVariant,
                id: uuidv4(),
                productId: product.id
              })
            }
          }}
          items={
            fields.map((field) => {
              const variant = form.getFieldValue('variants')[field.key]
              const shopName = shops.find(shop => shop.id === variant.shopId)?.name
              const color = productColors.find(({ id }) => id === variant.colorId)
              const colorTitle = color?.translations.find(({ locale }) => locale === 'en').title
              const tabName = (shopName ?? '<No Shop>') + (_.isEmpty(colorTitle) ? '' : ` (${colorTitle})`)
              const lowStock = variant.futureDeliveryDate && variant.availableQuantity <= 0
                ? variant.futureQuantity < 10
                : variant.warehouseQuantity > 0 && variant.availableQuantity < 10
              const decoratedTabName = lowStock ? <Text type="danger">{tabName}</Text> : tabName

              return {
                key: field.key,
                label: (
                  <div style={{ marginLeft: '20px', textDecoration: variant.enabled ? 'none' : 'line-through' }}>
                    <Form.Item
                      name={[field.name, 'enabled']}
                      valuePropName="checked"
                      style={{ position: 'absolute', top: 3, left: 11 }}
                    >
                      <Checkbox />
                    </Form.Item>
                    {decoratedTabName}
                  </div>
                ),
                closable: false,
                children: (
                  <Row gutter={[24, 24]}>
                    <Col xs={12}>
                      <Row gutter={[16, 16]}>
                        <Col xs={12}>
                          <Form.Item
                            label={fm('label.sku')}
                            name={[field.name, 'sku']}
                            required
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col xs={12}>
                          <Form.Item
                            label={fm('label.ean')}
                            name={[field.name, 'ean']}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col xs={8}>
                          <Form.Item
                            label={fm('label.quantity')}
                            name={[field.name, 'availableQuantity']}
                          >
                            <InputNumber style={{ width: '100%' }} />
                          </Form.Item>
                          <p>
                            Warehouse quantity: {product.variants[field.key]?.warehouseQuantity}
                          </p>
                        </Col>
                        <Col xs={8}>
                          <Form.Item
                            label={fm('label.future-quantity')}
                            name={[field.name, 'futureQuantity']}
                          >
                            <InputNumber style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                        <Col xs={8}>
                          <Form.Item
                            label={fm('label.future-delivery-date')}
                            name={[field.name, 'futureDeliveryDate']}
                          >
                            <DatePicker style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item
                        label={fm('label.color')}
                        name={[field.name, 'colorId']}
                        hasFeedback
                        rules={[
                          {
                            required: !_.isEmpty(productColors),
                            message: 'Please select color'
                          }
                        ]}
                      >
                        <Select
                          disabled={_.isEmpty(productColors)}
                          placeholder={
                            _.isEmpty(productColors)
                              ? 'Please add colors to the product'
                              : 'Please select color'
                          }
                        >
                          {productColors.map(
                            (color) => (
                              <Select.Option
                                key={color.id}
                                value={color.id}
                              >
                                {color.translations.find(({ locale }) => locale === 'en').title}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={fm('label.shop')}
                        name={[field.name, 'shopId']}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Please select shop'
                          }
                        ]}
                      >
                        <Select placeholder="Please select a shop">
                          {shops.map(
                            (shop) => (
                              <Select.Option
                                key={shop.id}
                                value={shop.id}
                              >
                                {shop.name}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={fm('label.countries')}
                        name={[field.name, 'countryIds']}
                        hasFeedback
                      >
                        <Select
                          showSearch
                          placeholder="Please select a country"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          notFoundContent={null}
                          mode="multiple"
                        >
                          {shopCountries[variant.shopId].map((el) => (
                            <Select.Option
                              key={el.id}
                              value={el.id}
                            >
                              {el.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <MediaGroupFields holderType="ProductVariant" namespace={[field.name]} />
                      <Form.Item
                        label={fm('label.sage-warehouse')}
                        name={[field.name, 'sageWarehouseId']}
                        hasFeedback
                      >
                        <Select allowClear placeholder="Please select a warehouse">
                          {sageWarehouses.map(
                            (el) => (
                              <Select.Option
                                key={el.id}
                                value={el.id}
                              >
                                {el.knownAs}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <Form.Item
                        name={[field.name, 'sadVat']}
                        valuePropName="checked"
                      >
                        <Checkbox
                          style={{ marginTop: 28 }}
                        >
                          {fm('label.sad-vat')}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Row justify="end">
                        <Button
                          onClick={() => remove(field.name)}
                        >
                          <DeleteOutlined
                            style={{ marginRight: 8 }}
                          />
                          {fm('button.delete')}
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                )
              }
            })
          }
        />
      )}
    </Form.List>
  )
}
