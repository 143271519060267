import { useCallback, useEffect, useState } from 'react'
import { Card, Row, Col, Form, Input, Select, PageHeader } from 'antd'
import { css } from '@linaria/core'
import { useDispatch, useSelector } from 'react-redux'

import { fm } from '../../../lang'
import {
  createUser,
  deleteUser,
  resetUser,
  selectUser,
  updateUser
} from '../../../data/actions/users'
import { useRecordHook } from '../../../helpers/hooks/useRecordHook'
import { FormActionBox } from '../../Shared/FormActionBox'
import { usePush } from '../../../data/actions/routes'
import { useAllow } from '../../../helpers/hooks/usePermissions'
import { showError } from '../../../data/actions/ui'
import { useBreadcrumb } from '../../../helpers/hooks/useBreadcrumb'
import { onKeyDownForm } from '../../../helpers/form'

const tableWrapper = css`
  width: 432px;
  .ant-card-body {
    padding: 16px;
  }
`

const formClass = css`
  width: 400px;
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

const { Option } = Select

export const UserForm = ({ isLoading, path }) => {
  const allow = useAllow()
  const breadcrumb = useBreadcrumb(path)
  const push = usePush()
  const { newRecord, params } = useRecordHook()
  const { id } = params
  const { entry, availableRoles } = useSelector((state) => state.users)
  const dispatch = useDispatch()
  const [requiredPassword, setRequiredPassword] = useState(false)
  const [form] = Form.useForm()
  const handleBack = useCallback(() => {
    push('/settings/users')
  }, [push])
  useEffect(() => {
    if (!isLoading) {
      if (entry === undefined) {
        showError(dispatch, 'record-not-found')
        resetUser(dispatch)
        handleBack()
      } else {
        form.setFieldsValue(entry)
      }
    }
  }, [entry])
  const onFinish = useCallback(
    (values) => {
      if (newRecord) {
        createUser(dispatch, { ...entry, ...values }, push)
      } else {
        updateUser(dispatch, { id, ...values }, push)
      }
    },
    [newRecord, id, dispatch]
  )
  const deleteRecord = useCallback(() => {
    deleteUser(dispatch, id, push)
  }, [id, push, dispatch])
  const onValuesChange = ({ password }) => {
    if (password !== undefined) {
      setRequiredPassword(password !== '')
    }
  }
  useEffect(() => {
    if (!isLoading && !newRecord) {
      selectUser(dispatch, id)
    } else if (newRecord) {
      resetUser(dispatch)
    }
  }, [isLoading, dispatch, selectUser, resetUser])
  const cardTitle = newRecord ? 'new' : 'edit'
  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm(`user.form.${cardTitle}`)}
      onBack={handleBack}
    >
      <Card size="small" className={tableWrapper} loading={isLoading}>
        <Row>
          <Col xs={24}>
            <Form
              form={form}
              layout="vertical"
              className={formClass}
              onValuesChange={onValuesChange}
              onKeyDown={onKeyDownForm}
              onFinish={onFinish}
            >
              <Form.Item
                name="email"
                label={fm('label.email')}
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail'
                  },
                  {
                    required: true,
                    message: 'Please input E-mail'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="firstName"
                label={fm('label.first-name')}
                rules={[
                  {
                    required: true,
                    message: 'Please input first name'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="lastName"
                label={fm('label.last-name')}
                rules={[
                  {
                    required: true,
                    message: 'Please input last name'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="roleId"
                label={fm('label.role')}
                hasFeedback
                rules={[{ required: true, message: 'Please select role' }]}
              >
                <Select
                  placeholder="Please select a role"
                  disabled={!allow('can_change_user_role')}
                >
                  {availableRoles.map((role) => (
                    <Option key={role.id} value={role.id}>
                      {role.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="password"
                label={fm('label.password')}
                rules={[
                  {
                    required: newRecord ? true : requiredPassword,
                    message: 'Please input your password'
                  }
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="confirm"
                label={fm('label.confirm-password')}
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: newRecord ? true : requiredPassword,
                    message: 'Please confirm your password'
                  },
                  ({ getFieldValue }) => ({
                    validator (_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(
                          'The two passwords that you entered do not match'
                        )
                      )
                    }
                  })
                ]}
              >
                <Input.Password />
              </Form.Item>
              <FormActionBox
                isLoading={isLoading}
                newRecord={newRecord}
                onDelete={deleteRecord}
              />
            </Form>
          </Col>
        </Row>
      </Card>
    </PageHeader>
  )
}
