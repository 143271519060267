import Ui from './ui'
import Tunes from './tunes'
import Uploader from './uploader'

export default class ProductDoubleImage {
  static get isReadOnlySupported () {
    return true
  }

  static get toolbox () {
    return {
      icon:
        '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150.242V79c0-18.778-15.222-34-34-34H79c-18.778 0-34 15.222-34 34v42.264l67.179-44.192 80.398 71.614 56.686-29.14L291 150.242zm-.345 51.622l-42.3-30.246-56.3 29.884-80.773-66.925L45 174.187V197c0 18.778 15.222 34 34 34h178c17.126 0 31.295-12.663 33.655-29.136zM79 0h178c43.63 0 79 35.37 79 79v118c0 43.63-35.37 79-79 79H79c-43.63 0-79-35.37-79-79V79C0 35.37 35.37 0 79 0z"/></svg>',
      title: 'Double image'
    }
  }

  /**
   * @param {object} tool - tool properties got from editor.js
   * @param {ImageToolData} tool.data - previously saved data
   * @param {ImageConfig} tool.config - user config for Tool
   * @param {object} tool.api - Editor.js API
   * @param {boolean} tool.readOnly - read-only mode flag
   */
  constructor ({ data, config, api, readOnly }) {
    this.api = api
    this.readOnly = readOnly
    this.config = {
      endpoints: config.endpoints || '',
      additionalRequestData: config.additionalRequestData || {},
      additionalRequestHeaders: config.additionalRequestHeaders || {},
      field: config.field || 'image',
      types: config.types || 'image/*',
      altPlaceholder: 'Alt',
      buttonContent: config.buttonContent || '',
      uploader: config.uploader || undefined,
      actions: config.actions || []
    }
    this.uploader = new Uploader({
      config: this.config,
      onUpload: (response, index) => this.onUpload(response, index),
      onError: (error, index) => this.uploadingFailed(error, index)
    })
    this.ui = new Ui({
      api,
      config: this.config,
      onSelectFile: (nodeNumber) => {
        this.uploader.uploadSelectedFile({
          onPreview: (src) => {
            this.ui.showPreloader(nodeNumber, src)
          },
          index: nodeNumber
        })
      },
      readOnly
    })
    this.tunes = new Tunes({
      api,
      actions: this.config.actions,
      onChange: (tuneName) => this.tuneToggled(tuneName)
    })
    this._data = {}
    this.data = data
  }

  /**
   * Renders Block content
   *
   * @public
   *
   * @returns {HTMLDivElement}
   */
  render () {
    return this.ui.render(this.data)
  }

  /**
   * Return Block data
   *
   * @public
   *
   * @returns {ImageToolData}
   */
  save () {
    [0, 1].forEach((i) => {
      const values = {
        alt: this.ui.nodes[`alt${i}`].value
      }
      this._data.items[i] = { ...this._data.items[i], ...values }
    })
    return this.data
  }

  /**
   * Makes buttons with tunes: add background, add border, stretch image
   *
   * @public
   *
   * @returns {Element}
   */
  renderSettings () {
    return this.tunes.render(this.data)
  }

  /**
   * Fires after clicks on the Toolbox Image Icon
   * Initiates click on the Select File button
   *
   * @public
   */
  appendCallback () {
    // this.ui.nodes.fileButton.click();
  }

  /**
   * Specify paste substitutes
   *
   * @see {@link https://github.com/codex-team/editor.js/blob/master/docs/tools.md#paste-handling}
   * @returns {{tags: string[], patterns: object<string, RegExp>, files: {extensions: string[], mimeTypes: string[]}}}
   */
  static get pasteConfig () {
    return false
  }

  /**
   * Specify paste handlers
   *
   * @public
   * @see {@link https://github.com/codex-team/editor.js/blob/master/docs/tools.md#paste-handling}
   * @param {CustomEvent} event - editor.js custom paste event
   *                              {@link https://github.com/codex-team/editor.js/blob/master/types/tools/paste-events.d.ts}
   * @returns {void}
   */
  async onPaste () {}

  set data (data) {
    this._data = {
      items: [
        { alt: '', file: {} },
        { alt: '', file: {} }
      ]
    };
    [0, 1].forEach((i) => {
      this._data.items[i].alt =
        data.items && data.items.length > 0 ? data.items[i].alt || '' : ''
      this.ui.fillInput(this._data.items[i].alt, `alt${i}`)
      this.image(
        data.items && data.items.length > 0 ? data.items[i].file : {},
        i
      )
    })

    Tunes.tunes.forEach(({ name: tune }) => {
      const value =
        typeof data[tune] !== 'undefined'
          ? data[tune] === true || data[tune] === 'true'
          : false
      this.setTune(tune, value)
    })
  }

  get data () {
    return this._data
  }

  onUpload (response, index) {
    if (response.success && response.file) {
      this.image(response.file, index)
    } else {
      this.uploadingFailed('incorrect response: ' + JSON.stringify(response))
    }
  }

  image (file, index) {
    this._data.items[index].file = file || {}
    if (file && file.url) {
      this.ui.fillImage(file.url, index)
    }
  }

  uploadingFailed (errorText, index) {
    console.log('Image Tool: uploading failed because of', errorText)

    this.api.notifier.show({
      message: this.api.i18n.t('Couldn’t upload image. Please try another.'),
      style: 'error'
    })
    this.ui.hidePreloader(index)
  }

  /**
   * Callback fired when Block Tune is activated
   *
   * @private
   *
   * @param {string} tuneName - tune that has been clicked
   * @returns {void}
   */
  tuneToggled (tuneName) {
    // inverse tune state
    this.setTune(tuneName, !this._data[tuneName])
  }

  /**
   * Set one tune
   *
   * @param {string} tuneName - {@link Tunes.tunes}
   * @param {boolean} value - tune state
   * @returns {void}
   */
  setTune (tuneName, value) {
    this._data[tuneName] = value

    this.ui.applyTune(tuneName, value)

    if (tuneName === 'stretched') {
      /**
       * Wait until the API is ready
       */
      Promise.resolve()
        .then(() => {
          const blockId = this.api.blocks.getCurrentBlockIndex()

          this.api.blocks.stretchBlock(blockId, value)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  /**
   * Show preloader and upload image file
   *
   * @param {File} file - file that is currently uploading (from paste)
   * @returns {void}
   */
  /* uploadFile(file, index) {
    this.uploader.uploadByFile(file, {
      onPreview: (src) => {
        this.ui.showPreloader(src, index);
      },
    });
  } */

  /**
   * Show preloader and upload image by target url
   *
   * @param {string} url - url pasted
   * @returns {void}
   */
  /* uploadUrl(url) {
    this.ui.showPreloader(url);
    this.uploader.uploadByUrl(url);
  } */
}
