import * as types from '../../constants/actionTypes/articlesActionTypes'
import { v4 as uuidv4 } from 'uuid'

export const publishedStatuses = ['all', 'published', 'draft']

const emptyTableFilters = {
  term: null,
  website: null,
  publishedStatus: 'all'
}

export const emptyTranslation = {
  locale: null,
  title: null,
  slug: null,
  theme: null,
  seoTitle: null,
  seoKeywords: null,
  seoDescription: null,
  socialTitle: null,
  socialDescription: null,
  editor: {}
}

const emptyArticle = {
  id: null,
  websiteIds: [],
  tagIds: [],
  published: false,
  publishedAt: null,
  translations: [{ ...emptyTranslation, locale: 'en' }]
}

const defaultState = {
  entry: { ...emptyArticle, id: uuidv4() },
  entries: [],
  tableFilters: emptyTableFilters,
  filtersEntries: {
    pagination: { current: 1, pageSize: 10, totalCount: 0, totalPage: 0 }
  }
}

export default function articlesReducer (state = defaultState, action) {
  switch (action.type) {
    case types.ARTICLES_UPDATE:
      return {
        ...state,
        entries: state.entries.map((u) => {
          if (u.id === action.article.id) {
            return { ...action.article }
          } else {
            return u
          }
        })
      }
    case types.ARTICLES_DELETE:
      return {
        ...state,
        entry: { ...emptyArticle, id: uuidv4() },
        entries: state.entries.filter((u) => u.id !== action.article.id)
      }
    case types.ARTICLE_FETCH:
      return { ...state, entry: action.data }
    case types.ARTICLES_CREATE:
      return {
        ...state,
        entries: [{ ...action.article }, ...state.entries]
      }
    case types.ARTICLE_SELECT:
      return { ...state, entry: state.entries.find((u) => u.id === action.id) }
    case types.ARTICLE_FORM_RESET:
      return { ...state, entry: { ...emptyArticle, id: uuidv4() } }
    case types.ARTICLES_CHANGE_TABLE_FILTER:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          [action.filterType]: action.filterValue
        }
      }
    case types.ARTICLES_FETCH:
      return {
        ...state,
        entries: action.data.records,
        filtersEntries: {
          ...state.filtersEntries,
          pagination: {
            ...state.filtersEntries.pagination,
            totalCount: action.data.totalCount,
            totalPage: action.data.totalPage
          }
        }
      }
    default:
      return state
  }
}
