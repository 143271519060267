import { useEffect } from 'react'
import { Modal, Select, Row, Form, Radio, Input, Col } from 'antd'
import { css } from '@linaria/core'
import { fm } from '../../../../lang'
import { useSelector } from 'react-redux'
import { TranslationTabs } from '../../../Shared/Translations/TranslationTabs'

const formClass = css`
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`
export const ModalLinkForm = ({
  saveLink,
  link,
  setModalOpen,
  modalOpen,
  files,
  emptyTranslation
}) => {
  const categories = useSelector((state) => state.categories.entries)
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue(link)
  }, [link, form])

  const handleOk = () => {
    form.submit()
  }

  const handleCancel = () => {
    setModalOpen(false)
  }

  const onFinish = () => {
    const values = form.getFieldsValue(true)
    saveLink(values)
    setModalOpen(false)
  }

  return (
    <>
      <Modal
        title="Link"
        open={modalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={400}
      >
        <Form
          initialValues={link}
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={formClass}
        >
          <Row>
            <Col xs={24}>
              <Form.Item name="linkType" label="Link type">
                <Radio.Group defaultValue="category" buttonStyle="solid">
                  <Radio.Button value="category">Category</Radio.Button>
                  <Radio.Button value="link">Link</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item shouldUpdate>
                {(f) => {
                  const linkType = f.getFieldValue('linkType')
                  return (
                    <>
                      {linkType === 'link' && (
                        <>
                          <Form.Item
                            label="Url"
                            name="url"
                            hasFeedback
                            required
                          >
                            <Input />
                          </Form.Item>
                          <TranslationTabs
                            form={form}
                            emptyTranslation={emptyTranslation}
                            fieldName="textContent"
                          >
                            {({ namespace, translation }) =>
                              <Form.Item
                                label="Title"
                                name={[namespace, 'title']}
                                required={translation.locale === 'en'}
                              >
                                <Input />
                              </Form.Item>}
                          </TranslationTabs>
                        </>
                      )}
                      {linkType === 'category' && (
                        <>
                          <Form.Item
                            label={fm('label.category')}
                            name="categoryId"
                            hasFeedback
                            required
                          >
                            <Select
                              placeholder="Please select a category"
                              style={{ width: 300 }}
                            >
                              {categories.map((el) => (
                                <Select.Option
                                  key={el.id}
                                  value={el.id}
                                  rules={[
                                    {
                                      required: true
                                    }
                                  ]}
                                >
                                  {el.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            name="hoverImageId"
                            label="Hover image"
                          >
                            <Select
                              style={{
                                width: '300px'
                              }}
                              notFoundContent={null}
                              size="large"
                              allowClear
                            >
                              {files.map((el) => (
                                <Select.Option
                                  key={el.blobId}
                                  value={el.blobId}
                                >
                                  <img
                                    src={el.thumbUrl}
                                    width={38}
                                    height={38}
                                  />
                                  <span>&nbsp;&nbsp;{el.name}</span>
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </>
                      )}
                    </>
                  )
                }}
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item name="position" label="Position">
                <Radio.Group defaultValue="top" buttonStyle="solid">
                  <Radio.Button value="top">Top</Radio.Button>
                  <Radio.Button value="bottom">Bottom</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}
