import * as types from '../../constants/actionTypes/authActionTypes'
import store from '../store'
import { resetToken } from '../../helpers/auth'
import { resetAccount } from './account'
import { AUTH_ANONYMOUS } from '../../constants/actionTypes/authActionTypes'

export const signIn = (dispatch, email, password) => {
  dispatch.local({
    type: types.AUTH_REQUEST
  })
  dispatch.sync({
    type: types.AUTH_SIGN_IN,
    email,
    password
  })
}

export const signOut = (dispatch) => {
  resetAccount()
  resetToken()
  dispatch.crossTab({ type: AUTH_ANONYMOUS })
  dispatch.sync({
    type: types.AUTH_SIGN_OUT
  })
}

export async function onAuth (userId, token) {
  await store.client.changeUser(userId, token)
  await store.client.node.waitFor('sending')
  await store.client.node.waitFor('synchronized')
  return true
}

export async function authByAnonymous (userId, token) {
  await store.client.node.waitFor('disconnected')
  await store.client.changeUser(userId, token)
  store.client.start()
  await store.client.node.waitFor('synchronized')
  return true
}
