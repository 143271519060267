import { useSelector } from 'react-redux'
import { Switch, BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import messages from './lang'
import { RootRoute } from './routes/RootRoute'

const defaultLanguage = 'en'

export const AppWrapper = () => {
  const { lang } = useSelector((state) => state.auth)
  return (
    <IntlProvider
      messages={messages[lang]}
      locale={lang}
      key={lang}
      defaultLocale={defaultLanguage}
    >
      <BrowserRouter>
        <Switch>
          <RootRoute />
        </Switch>
      </BrowserRouter>
    </IntlProvider>
  )
}
