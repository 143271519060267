import { useRef, useState } from 'react'
import { Col, Form, Input, Row, Select, Tabs } from 'antd'
import {
  CodepenCircleOutlined,
  Html5Outlined
} from '@ant-design/icons'
import { fm } from '../../../../lang'
import { DropsDocumentation } from './DropsDocumentation'
import { CodeInput } from '../CodeInput'
import { FormActionBox } from '../../../Shared/FormActionBox'
import { useDispatch, useSelector } from 'react-redux'
import { useRecordHook } from '../../../../helpers/hooks/useRecordHook'
import {
  removeFile,
  updateBlockLocal
} from '../../../../data/actions/blocks'
import { onKeyDownForm } from '../../../../helpers/form'
import { getBlocksTags } from '../../../../data/selectors/blocks'
import { PicturesWall } from '../../../Shared/PicturesWall'

const { Option } = Select
export const HtmlForm = ({ form, onFinish, onDelete, isLoading }) => {
  const blockTags = useSelector(getBlocksTags)
  const tags = blockTags.map((tag) => <Option key={tag}>{tag}</Option>)
  const refAceCode = useRef(null)
  const refAceStyle = useRef(null)
  const { newRecord, params } = useRecordHook()
  const [activeKey, setActiveKey] = useState('html')
  const { id } = params
  const { entry } = useSelector((state) => state.blocks)
  const dispatch = useDispatch()
  const { files, code, style } = entry || {}
  const addToCode = (uid) => {
    if (activeKey === 'html') {
      refAceCode.current.editor.insert(`{{ files[${uid}].url }}`)
    } else {
      refAceStyle.current.editor.insert(`"{{ files[${uid}].url }}"`)
    }
  }
  const handleChangeTab = (key) => {
    setActiveKey(key)
  }
  const afterUploadFile = (file) => {
    updateBlockLocal(dispatch, id, {
      lockVersion: file.lockVersion,
      files: [...form.getFieldValue('files'), { ...file, blobId: file.uid }]
    })
    form.setFieldsValue({
      files: [...form.getFieldValue('files'), { ...file, blobId: file.uid }]
    })
  }
  const afterRemoveFile = (file) => {
    removeFile(dispatch, id, file.blobId)
  }
  const handleCodeInput = (value) => {
    form.setFieldsValue({ code: value })
  }
  const handleStyleInput = (value) => {
    form.setFieldsValue({ style: value })
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onKeyDown={onKeyDownForm}
    >
      <Row gutter={[16, 0]}>
        <Col xs={18}>
          <Tabs
            tabBarExtraContent={<DropsDocumentation />}
            defaultActiveKey="HTML"
            activeKey={activeKey}
            onChange={handleChangeTab}
            items={[{
              key: 'html',
              label: <span><Html5Outlined />HTML</span>,
              children: (
                (entry.id === id || !id) && <CodeInput
                  height="calc(100vh - 350px)"
                  onChange={handleCodeInput}
                  defaultValue={code}
                  mode="html"
                  name="code"
                  refAce={refAceCode}
                                            />
              )
            }, {
              key: 'style',
              label: <span><CodepenCircleOutlined />CSS</span>,
              children: (
                (entry.id === id || !id) && <CodeInput
                  height="calc(100vh - 350px)"
                  onChange={handleStyleInput}
                  defaultValue={style}
                  mode="scss"
                  name="style"
                  refAce={refAceStyle}
                                            />
              )
            }]}
          />
        </Col>
        <Col xs={6}>
          <Form.Item
            name="name"
            label={fm('label.name')}
            rules={[
              {
                required: true,
                message: 'Please input name'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="tags" label={fm('label.tags')}>
            <Select mode="tags">
              {tags}
            </Select>
          </Form.Item>
          <Form.Item
            name={['properties', 'parentNodeClass']}
            label={fm('label.parent-node-class')}
          >
            <Input />
          </Form.Item>
          <PicturesWall
            style={{ marginTop: '30px', height: 'calc(100vh - 550px)', overflow: 'hidden auto' }}
            afterRemoveFile={afterRemoveFile}
            afterUploadFile={afterUploadFile}
            addToCode={addToCode}
            newRecord={newRecord}
            files={files}
            recordType="block"
            recordId={id}
            showCodeButton
          />
        </Col>
      </Row>
      <FormActionBox
        isLoading={isLoading}
        newRecord={newRecord}
        onDelete={onDelete}
      />
    </Form>
  )
}
