import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/mode-scss'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/ext-searchbox'

export const CodeInput = ({ mode, name, refAce, defaultValue, onChange, height }) => {
  return (
    <AceEditor
      ref={refAce}
      mode={mode}
      theme="monokai"
      name={name}
      defaultValue={defaultValue}
      onChange={onChange}
      fontSize={14}
      showPrintMargin
      showGutter
      highlightActiveLine
      width="100%"
      height={height}
      enableBasicAutocompletion
      enableLiveAutocompletion
      showLineNumbers
      tabSize={2}
      setOptions={{
        animatedScroll: true,
        scrollPastEnd: 0.1
      }}
    />
  )
}
