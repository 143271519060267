import { make } from './ui'

export default class Tunes {
  constructor ({ api, actions, onChange }) {
    this.api = api
    this.actions = actions
    this.onChange = onChange
    this.buttons = []
  }

  static get tunes () {
    return []
  }

  get CSS () {
    return {
      wrapper: '',
      buttonBase: this.api.styles.settingsButton,
      button: 'avatar-tool__tune',
      buttonActive: this.api.styles.settingsButtonActive
    }
  }

  render (toolData) {
    const wrapper = make('div', this.CSS.wrapper)

    this.buttons = []

    const tunes = Tunes.tunes.concat(this.actions)

    tunes.forEach((tune) => {
      const title = this.api.i18n.t(tune.title)
      const el = make('div', [this.CSS.buttonBase, this.CSS.button], {
        innerHTML: tune.icon,
        title
      })

      el.addEventListener('click', () => {
        this.tuneClicked(tune.name, tune.action)
      })

      el.dataset.tune = tune.name
      el.classList.toggle(this.CSS.buttonActive, toolData[tune.name])

      this.buttons.push(el)

      this.api.tooltip.onHover(el, title, {
        placement: 'top'
      })

      wrapper.appendChild(el)
    })

    return wrapper
  }

  tuneClicked (tuneName, customFunction) {
    if (typeof customFunction === 'function') {
      if (!customFunction(tuneName)) {
        return false
      }
    }

    const button = this.buttons.find((el) => el.dataset.tune === tuneName)

    button.classList.toggle(
      this.CSS.buttonActive,
      !button.classList.contains(this.CSS.buttonActive)
    )

    this.onChange(tuneName)
  }
}
