import { useEffect, useState } from 'react'
import { css } from '@linaria/core'
import { Typography, Row, Col, Button, Form, Input } from 'antd'
import { fm } from '../../lang'
import { useDispatch, useSelector } from 'react-redux'
import { updateAccount } from '../../data/actions/account'

const { Title } = Typography

const formClass = css`
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

const buttonSubmitWrapper = css`
  margin-top: 24px;
`

export const BasicSettings = () => {
  const dispatch = useDispatch()
  const [requiredPassword, setRequiredPassword] = useState(false)
  const { entry } = useSelector((state) => state.account)
  const { id } = entry
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue(entry)
  }, [form, entry])
  const onFinish = () => {
    const values = form.getFieldsValue(true)
    updateAccount(dispatch, { id, ...values })
  }
  const onValuesChange = ({ password }) => {
    if (password !== undefined) {
      setRequiredPassword(password !== '')
    }
  }
  return (
    <Row>
      <Col style={{ width: 400 }}>
        <Title level={4}>{fm('account.basic-settings.title')}</Title>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className={formClass}
          onValuesChange={onValuesChange}
        >
          <Form.Item name="email" label={fm('label.email')}>
            <Input placeholder="Email" disabled />
          </Form.Item>
          <Form.Item name="firstName" label={fm('label.first-name')}>
            <Input placeholder="First name" />
          </Form.Item>
          <Form.Item name="lastName" label={fm('label.last-name')}>
            <Input placeholder="Last name" />
          </Form.Item>
          <Form.Item
            name="password"
            label={fm('label.password')}
            rules={[
              {
                required: requiredPassword,
                message: 'Please input your password'
              }
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm"
            label={fm('label.confirm-password')}
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: requiredPassword,
                message: 'Please confirm your password'
              },
              ({ getFieldValue }) => ({
                validator (_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match'
                    )
                  )
                }
              })
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item className={buttonSubmitWrapper}>
            <Button type="primary" htmlType="submit">
              {fm('button.update-information')}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}
