import { Avatar, Dropdown } from 'antd'
import { css } from '@linaria/core'
import { useDispatch, useSelector } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { UserMenu } from './UserMenu'
import { usePush } from '../../../data/actions/routes'
import { signOut } from '../../../data/actions/auth'

const avatar = css`
  color: #fff;
  background: #fa8c16;
  &:hover {
    cursor: pointer;
    background: #d46b08;
  }
`

const formatShortName = (userData) => {
  const first = userData.firstName ? userData.firstName[0] : ''
  const last = userData.lastName ? userData.lastName[0] : ''
  return first + last
}

export const UserProfile = () => {
  const dispatch = useDispatch()
  const { userData } = useSelector((state) => state.auth)
  const shortName = formatShortName(userData)
  const push = usePush()
  const handleClick = ({ key }) => {
    if (key === 'account') push('/account')
    if (key === 'logout') {
      signOut(dispatch)
    }
  }
  return (
    <Dropdown
      menu={() => UserMenu({ handleClick })}
      trigger={['click']}
    >
      <Avatar className={avatar} icon={shortName ? null : <UserOutlined />}>
        {shortName}
      </Avatar>
    </Dropdown>
  )
}
