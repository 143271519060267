import { useCallback, useEffect, useState } from 'react'
import { Card, Row, Col, Form, Input, PageHeader, Tabs, Select, Typography, DatePicker, notification } from 'antd'
import moment from 'moment'
import { css } from '@linaria/core'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import { fm } from '../../../lang'
import {
  createPage,
  deletePage,
  removeBlock,
  resetPage,
  selectPage,
  updatePage,
  updatePageLocal
} from '../../../data/actions/pages'
import { useRecordHook } from '../../../helpers/hooks/useRecordHook'
import { FormActionBox } from '../../Shared/FormActionBox'
import { usePush } from '../../../data/actions/routes'
import { showError } from '../../../data/actions/ui'
import { useBreadcrumb } from '../../../helpers/hooks/useBreadcrumb'
import { PageBlockForm } from './PageBlockForm'
import { onKeyDownForm } from '../../../helpers/form'
import { getObjectListBlocks } from '../../../data/selectors/blocks'
import { v4 as uuidv4 } from 'uuid'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { TranslationTabs } from '../../Shared/Translations/TranslationTabs'
import { TranslationFields } from './PageForm/TranslationFields'
import { emptyTranslation } from '../../../data/reducers/pagesReducer'

const routeTabs = {
  '/content/pages/:id': 'basic',
  '/content/pages/:id/blocks': 'blocks'
}
const tabRoutes = {
  blocks: 'blocks'
}

const formClass = css`
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

const initialFormValues = { values: {}, errors: [] }

export const PageForm = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)
  const push = usePush()
  const { newRecord, params } = useRecordHook()
  const location = useLocation()
  const { id } = params
  const { entry } = useSelector((state) => state.pages)
  const websites = useSelector((state) => state.websites.entries)
  const allTags = useSelector((state) => Array.from(state.pages.entries.reduce(
    (result, { tags }) => {
      tags.forEach((tag) => result.add(tag))
      return result
    }, new Set()
  )).sort())
  const availableProducts = useSelector((state) => state.products.entries)
  const availableTemplates = useSelector((state) => state.templates.entries)
  const blocksList = useSelector(getObjectListBlocks)
  const components = entry !== undefined ? entry.components : []
  const { kind, system } = entry || {}
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(initialFormValues)
  const [form] = Form.useForm()
  const { path: routePath } = useRouteMatch()
  const activeTabKey = routeTabs[routePath]

  const handleBack = useCallback(() => {
    push('/content/pages')
  }, [push])
  const handleTabChange = useCallback((key) => {
    push(`/content/pages/${id}/${tabRoutes[key] ?? ''}`)
  }, [push, id])
  useEffect(() => {
    if (!isLoading) {
      if (entry.notFound) {
        showError(dispatch, 'record-not-found')
        resetPage(dispatch)
        handleBack()
      } else {
        form.setFieldsValue({
          ...entry,
          publishedAt: entry.publishedAt ? moment(entry.publishedAt) : null
        })
        form.setFieldsValue(formValues.values)
        form.setFields(formValues.errors)
      }
    }
  }, [entry, dispatch, form, handleBack, isLoading, formValues])
  const updateBlocks = useCallback((values) => {
    const oldComponents = form.getFieldValue('components')
    const newComponents = oldComponents
      ? oldComponents.map((el) => {
        const newEl = values.find((i) => i.id === el.id)
        if (newEl) {
          return newEl
        } else {
          return el
        }
      })
      : []
    form.setFieldsValue({ components: newComponents })
  }, [form])
  const addBlock = (blockId) => {
    const oldComponents = form.getFieldValue('components')
    const block = blocksList[blockId]
    const newBlock = {
      id: uuidv4(),
      name: block ? block.name : '—',
      blockableId: blockId,
      blockableType: 'Block',
      index: oldComponents.length
    }
    form.setFieldsValue({ components: oldComponents.concat(newBlock) })
  }
  const deleteBlock = (blockId) => {
    const oldComponents = form.getFieldValue('components')
    const newComponents = removeBlock(oldComponents, blockId)
    form.setFieldsValue({ components: newComponents })
  }
  useEffect(() => {
    updateBlocks(components)
  }, [components, updateBlocks])
  const onFinish = useCallback(async () => {
    const values = form.getFieldsValue(true)

    try {
      setFormValues({ values, errors: formValues.errors.map(({ name }) => ({ name, errors: [] })) })

      if (newRecord) {
        const createParams = { ...entry, ...values, publishedAt: values.publishedAt && values.publishedAt.format() }
        await createPage(dispatch, createParams)
        push(`/content/pages/${createParams.id}`)
        notification.success({ message: 'Page successfully created' })
      } else {
        const updateParams = { id, ...values, publishedAt: values.publishedAt && values.publishedAt.format() }
        await updatePage(dispatch, updateParams)
        notification.success({ message: 'Page successfully updated' })
      }
    } catch (e) {
      const errors = e.action.reason.map(({ path, message }) => (
        {
          name: path,
          errors: [message]
        }
      ))

      setFormValues({ values, errors })
    }
  }, [newRecord, id, dispatch, entry, form, push])
  const deleteRecord = useCallback(() => {
    deletePage(dispatch, id, push)
  }, [id, push, dispatch])
  useEffect(() => {
    if (!isLoading && !newRecord) {
      selectPage(dispatch, id)
    } else if (newRecord) {
      resetPage(dispatch)
      const query = new URLSearchParams(location.search)
      const queryKind = query.get('kind')
      if (queryKind) {
        updatePageLocal(dispatch, 'kind', queryKind, id)
      }
    }
  }, [isLoading, dispatch, newRecord, id, location])
  const cardTitle = newRecord ? 'new' : 'edit'

  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm(`page.form.${cardTitle}`)}
      onBack={handleBack}
    >
      <Form
        form={form}
        layout="vertical"
        className={formClass}
        onKeyDown={onKeyDownForm}
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Tabs
              defaultActiveKey="basic"
              activeKey={activeTabKey}
              onChange={handleTabChange}
              items={[{
                key: 'basic',
                label: fm('tab.basic'),
                children: (
                  <Row gutter={[16, 16]}>
                    <Col span={8}>
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Card
                            loading={isLoading}
                            size="small"
                          >
                            <Form.Item
                              name="name"
                              label={fm('label.name')}
                              rules={[
                                {
                                  required: !system,
                                  message: 'Please input name'
                                }
                              ]}
                            >
                              <Input disabled={system} readOnly={system} />
                            </Form.Item>
                            <Form.Item
                              name="websiteIds"
                              label={fm('label.websites')}
                              hasFeedback
                            >
                              <Select
                                placeholder="Please select websites"
                                mode="multiple"
                              >
                                {websites.map((website) => (
                                  <Select.Option key={website.id} value={website.id}>
                                    {website.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              name="tags"
                              label={fm('label.tags')}
                              hasFeedback
                            >
                              <Select
                                placeholder="Please select tags"
                                mode="tags"
                              >
                                {allTags.map((tag) => (
                                  <Select.Option key={tag} value={tag}>
                                    {tag}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Typography.Paragraph type="secondary">
                              These tags are technical and will be used for page links rendering
                            </Typography.Paragraph>
                            {(kind === 'product' || kind === 'support' || kind === 'collection') && (
                              <Form.Item
                                name="productIds"
                                label={fm('label.products')}
                                hasFeedback
                              >
                                <Select
                                  placeholder="Please select products"
                                  mode="multiple"
                                  filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())}
                                >
                                  {availableProducts.map((product) => (
                                    <Select.Option
                                      key={product.id}
                                      value={product.id}
                                    >
                                      {product.translations.find(({ locale }) => locale === 'en').name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            )}
                            {kind === 'system' && (
                              <Form.Item
                                name="templateId"
                                label={fm('label.template')}
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please select page template'
                                  }
                                ]}
                              >
                                <Select placeholder="Please select a page template">
                                  {availableTemplates.map((el) => (
                                    <Select.Option
                                      key={el.id}
                                      value={el.id}
                                    >
                                      {el.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            )}
                            <Form.Item
                              name="publishedAt"
                              label={fm('label.published-at')}
                            >
                              <DatePicker showTime showNow />
                            </Form.Item>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={16}>
                      <Card
                        loading={isLoading}
                        size="small"
                      >
                        <TranslationTabs form={form} emptyTranslation={emptyTranslation}>
                          {({ namespace, translation }) =>
                            <TranslationFields
                              namespace={namespace}
                              locale={translation.locale}
                            />}
                        </TranslationTabs>
                      </Card>
                    </Col>
                  </Row>
                )
              }, {
                key: 'blocks',
                label: fm('tab.blocks'),
                children: (
                  <Form.Item shouldUpdate>
                    {(f) => {
                      const filteredComponents = _.orderBy(
                        (f.getFieldValue('components') ?? []).filter(
                          el => !el._destroy
                        ), ['index', 'asc']
                      )
                      const templateId = f.getFieldValue('templateId')
                      return (
                        <PageBlockForm
                          addBlock={addBlock}
                          removeBlock={deleteBlock}
                          updateBlocks={updateBlocks}
                          components={filteredComponents}
                          templateId={templateId}
                        />
                      )
                    }}
                  </Form.Item>
                )
              }]}
            />
            {!system && (
              <FormActionBox
                isLoading={isLoading}
                newRecord={newRecord}
                onDelete={deleteRecord}
              />
            )}
          </Col>
        </Row>
      </Form>
    </PageHeader>
  )
}
