import { Switch } from 'react-router-dom'
import { useSubscription } from '@logux/redux'

import { useAllow } from '../../helpers/hooks/usePermissions'
import { PrivateRoute } from '../PrivateRoute'
import { BlocksScreen } from '../../screens/Blocks/BlocksScreen'
import { BlockFormScreen } from '../../screens/Blocks/BlockFormScreen'

export const BlocksRouter = ({ isAuthenticated }) => {
  const isLoading = useSubscription(['blocks', 'categories'])
  const allow = useAllow()
  return (
    <Switch>
      <PrivateRoute
        allow={allow('can_create_block')}
        path="/content/blocks/new"
        exact
        isAuthenticated={isAuthenticated}
        component={BlockFormScreen}
      />
      <PrivateRoute
        allow={allow('can_edit_block')}
        path="/content/blocks/:id"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={BlockFormScreen}
      />
      <PrivateRoute
        allow={allow('can_show_blocks')}
        path="/content/blocks"
        exact
        isAuthenticated={isAuthenticated}
        componentProps={{ isLoading }}
        component={BlocksScreen}
      />
    </Switch>
  )
}
