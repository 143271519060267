import { useCallback } from 'react'
import { Card, Table, PageHeader, Button, Dropdown, Typography } from 'antd'
import { DownOutlined, TranslationOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { kinds } from '../../../data/reducers/pagesReducer'
import { fm } from '../../../lang'
import { usePush } from '../../../data/actions/routes'
import { useAllow } from '../../../helpers/hooks/usePermissions'
import { useBreadcrumb } from '../../../helpers/hooks/useBreadcrumb'
import { PagesTableHeader } from './PagesTableHeader'
import { getFilteredPages } from '../../../data/selectors/pages'
import { fetchTranslate } from '../../../data/actions/pages'
import { WebsitesCell } from '../../Shared/WebsitesCell'
import { SHOP_URL } from '../../../constants/api'

const addMenuItems = kinds.map((kind) => ({ key: kind, label: fm(`pages.table.menu.${kind}`) }))
const columns = [
  {
    title: fm('table.name'),
    key: 'name',
    dataIndex: 'name',
    render: (name, record) => (
      (record.publishedAt && moment(record.publishedAt).isBefore(moment()))
        ? name
        : <Typography.Text type="secondary">{name}</Typography.Text>
    )
  },
  {
    title: fm('table.kind'),
    key: 'kind',
    dataIndex: 'kind',
    width: 100
  },
  {
    title: fm('table.published-at'),
    key: 'publishedAt',
    dataIndex: 'publishedAt',
    width: 200,
    render: (publishedAt) => publishedAt && moment(publishedAt).format('MMMM Do YYYY')
  },
  {
    title: fm('table.websites'),
    key: 'websiteIds',
    dataIndex: 'websiteIds',
    width: 250,
    render: (websiteIds, record) => (
      record.translations.find(({ locale }) => locale === 'en').slug || !record.publishedAt
        ? (
          <WebsitesCell websiteIds={websiteIds}>
            {({ website, name }) => {
              let pageUrl = null
              if (record.publishedAt && moment(record.publishedAt).isBefore(moment())) {
                const websiteTranslation = record.translations.find(({ locale }) => locale === website.locale)
                const defaultTranslation = record.translations.find(({ locale }) => locale === 'en')
                const slug = (websiteTranslation && websiteTranslation.slug) || defaultTranslation.slug

                pageUrl = new URL(`${website.prefix}${slug}`, SHOP_URL)
              } else {
                pageUrl = new URL(`${website.prefix}/_drafts/${record.id}`, SHOP_URL)
              }
              return (<a href={pageUrl} target="_blank" rel="noreferrer">{name}</a>)
            }}
          </WebsitesCell>
          )
        : <WebsitesCell websiteIds={websiteIds} />
    )
  }
]

export const PagesTable = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)
  const allow = useAllow()
  const push = usePush()
  const dispatch = useDispatch()
  const { translateFetching } = useSelector((state) => state.pages.requests)
  const entries = useSelector(getFilteredPages)
  const handleClickTranslate = () => {
    fetchTranslate(dispatch)
  }
  const handleAddNewPage = ({ key }) => {
    push(`/content/pages/new?kind=${key}`)
  }
  const handleClickRow = useCallback(
    (recordId) => {
      push(`/content/pages/${recordId}`)
    },
    [push]
  )

  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm('pages.title')}
      extra={[
        <Button
          type="secondary"
          onClick={handleClickTranslate}
          icon={<TranslationOutlined />}
          loading={translateFetching}
          key="fetchTranslations"
        >
          <span style={{ marginLeft: 8 }}>{fm('button.fetch-translate')}</span>
        </Button>,
        <Dropdown
          menu={{ items: addMenuItems, onClick: handleAddNewPage }}
          key="addNewPage"
        >
          <Button>
            {fm('button.add')} <DownOutlined />
          </Button>
        </Dropdown>
      ]}
    >
      <Card size="small">
        {allow('can_create_page') && <PagesTableHeader />}
      </Card>
      <Card size="small">
        <Table
          onRow={(record) => {
            return {
              onClick: () => handleClickRow(record.id)
            }
          }}
          dataSource={entries}
          columns={columns}
          size="small"
          loading={isLoading}
          rowKey="id"
        />
      </Card>
    </PageHeader>
  )
}
