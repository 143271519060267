import { Row, Col, Layout } from 'antd'
import { css } from '@linaria/core'
import { LoginForm } from '../components/Login/LoginForm'

import logoSrc from '../assets/logo.png'

const wrapper = css`
  height: 100vh;
`
const formInner = css`
  width: 320px;
  margin-bottom: 16px;
`
const logo = css`
  width: 140px;
  margin-bottom: 16px;
`

const logoWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const LoginScreen = () => {
  return (
    <Layout>
      <Row justify="center" align="middle" className={wrapper}>
        <Row className={formInner}>
          <Col xs={24}>
            <Row justify="center" className={logoWrapper}>
              <img src={logoSrc} className={logo} />
            </Row>
          </Col>
          <Col xs={24}>
            <LoginForm />
          </Col>
        </Row>
      </Row>
    </Layout>
  )
}
