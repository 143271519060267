import { useCallback } from 'react'
import { Card, Table, Button, PageHeader } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import { fm } from '../../lang'
import { usePush } from '../../data/actions/routes'
import { useAllow } from '../../helpers/hooks/usePermissions'
import { useBreadcrumb } from '../../helpers/hooks/useBreadcrumb'
import {
  changeFiltersCustomers,
  selectCustomer
} from '../../data/actions/customers'
import { CustomersTableHeader } from './Table/Header'

const columns = [
  {
    title: fm('table.full-name'),
    key: 'fullName',
    render: (value, record) => {
      return `${record.fullName}`
    }
  },
  {
    title: fm('table.email'),
    key: 'email',
    dataIndex: 'email'
  }
]
export const CustomersTable = ({ path, isLoading }) => {
  const breadcrumb = useBreadcrumb(path)
  const allow = useAllow()
  const push = usePush()
  const dispatch = useDispatch()
  const { entries } = useSelector((state) => state.customers)
  const filters = useSelector((state) => state.customers.filtersEntries)
  const { pagination } = filters
  const { totalCount } = pagination
  const handleClickAdd = () => {
    push('/customers/new')
  }
  const handleClickRow = useCallback(
    (recordId) => {
      selectCustomer(dispatch, recordId)
      push(`/customers/${recordId}`)
    },
    [push, dispatch, selectCustomer]
  )
  const onChange = (values) => {
    const { current, pageSize } = values
    changeFiltersCustomers(dispatch, 'pagination', {
      ...pagination,
      current,
      pageSize
    })
  }
  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm('customers.title')}
      extra={
        allow('can_create_customer') && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleClickAdd}
          >
            {fm('button.add')}
          </Button>
        )
      }
    >
      <Card size="small">
        <CustomersTableHeader />
        <Table
          onRow={(record) => {
            return {
              onClick: () => handleClickRow(record.id)
            }
          }}
          dataSource={entries}
          columns={columns}
          size="small"
          loading={isLoading}
          rowKey="id"
          onChange={onChange}
          pagination={{ total: totalCount }}
        />
      </Card>
    </PageHeader>
  )
}
