import { useEffect, useState, useCallback } from 'react'
import { css } from '@linaria/core'
import { Upload, Modal, Button, Drawer, Typography } from 'antd'
import {
  PlusOutlined,
  UploadOutlined,
  LoadingOutlined,
  CheckCircleTwoTone,
  WarningOutlined
} from '@ant-design/icons'
import { RAILS_SERVER } from '../../constants/api'
import { useDispatch } from 'react-redux'
import { deleteFile } from '../../data/actions/files'
import { fm } from '../../lang'

function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const imgBlock = css`
  display: flex;
  flex-direction: column;
  height: 128px;
`

const uploadFiles = css`
  position: relative;
  .ant-progress {
    display: flex;
    flex-direction: column;
  }
  .ant-upload-list-picture-card-container {
    min-height: 128px;
  }
  &--only-button {
  .ant-upload-list {
    display: none;
  }
`

const selectIcon = (status) => {
  switch (status) {
    case 'uploading':
      return <LoadingOutlined style={{ marginRight: 4 }} />
    case 'done':
      return (
        <CheckCircleTwoTone twoToneColor="#52c41a" style={{ marginRight: 4 }} />
      )
    case 'error':
      return <WarningOutlined style={{ marginRight: 4 }} />
    default:
      return <UploadOutlined style={{ marginRight: 4 }} />
  }
}

export const PicturesWall = ({
  addToCode,
  afterUploadFile,
  afterRemoveFile,
  recordType,
  recordId,
  listType,
  newRecord,
  fileType,
  files,
  showCodeButton,
  onlyButton,
  multiple,
  data,
  style
}) => {
  const dispatch = useDispatch()
  const [fileList, setFileList] = useState([])
  const [status, setStatus] = useState(null)
  const [state, setState] = useState({
    previewVisible: false,
    previewImage: '',
    previewTitle: ''
  })
  useEffect(() => {
    setFileList(files)
  }, [files])

  const [documentationOpen, setDocumentationOpen] = useState(false)

  const showDocumentation = useCallback(() => {
    setDocumentationOpen(true)
  }, [setDocumentationOpen])

  const hideDocumentation = useCallback(() => {
    setDocumentationOpen(false)
  }, [setDocumentationOpen])

  const handleCancel = () => setState({ previewVisible: false })

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setState({
      previewImage: file.preview || file.url,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    })
  }
  const handleChange = ({ file, fileList }) => {
    setFileList(fileList)
    setStatus(file.status)
  }

  const handleClickAddToCode = (uid) => {
    addToCode(uid)
  }

  const { previewVisible, previewImage, previewTitle } = state
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )
  const onSuccess = function onSuccess (response, file) {
    try {
      if (typeof response === 'string') {
        response = JSON.parse(response)
      }
    } catch {
      setStatus('error')
    }
    setStatus('done')
    const index = fileList.findIndex((f) => {
      return f.uid === file.uid
    })
    const newList = [...fileList]
    newList[index] = {
      ...fileList[index],
      status: 'done',
      blobId: response.uid
    }
    setFileList(newList)
    afterUploadFile && afterUploadFile(response)
  }
  const onRemove = (e) => {
    deleteFile(dispatch, e.blobId, recordType)
    afterRemoveFile && afterRemoveFile(e)
  }
  return (
    <div
      style={style}
      className={`uploadFiles ${
        onlyButton ? `${uploadFiles}--only-button` : ''
      }`}
    >
      {addToCode && (
        <>
          <Button type="link" onClick={showDocumentation}>
            Uploads documentation
          </Button>
          <Drawer title="Uploads documentation" placement="right" size="large" onClose={hideDocumentation} open={documentationOpen}>
            <Typography>
              <ul>
                <li>A URL to the file as it was uploaded: <pre>&#123;&#123; files[12345].url &#125;&#125;</pre></li>
                <li>Convert image format: <pre>&#123;&#123; files[12345] | convert: &#39;png&#39; &#125;&#125;</pre></li>
                <li>Resize to image with 300px width: <pre>&#123;&#123; files[12345] | convert: w: 300 &#125;&#125;</pre></li>
                <li>Resize to image with 300px height: <pre>&#123;&#123; files[12345] | convert: h: 300 &#125;&#125;</pre></li>
                <li>Convert to change quality: <pre>&#123;&#123; files[12345] | convert: q: 85 &#125;&#125;</pre></li>
                <li>Everything alltogether: <pre>&#123;&#123; files[12345] | convert: &#39;png&#39;, q: 85, w: 300, h: 300 &#125;&#125;</pre></li>
              </ul>
            </Typography>
          </Drawer>
        </>
      )}
      <Upload
        action={`${RAILS_SERVER}/api/v1/files/upload`}
        listType={listType}
        fileList={fileList}
        multiple={multiple}
        showUploadList={{ showDownloadIcon: true }}
        onPreview={handlePreview}
        onChange={handleChange}
        onSuccess={onSuccess}
        onRemove={onRemove}
        data={
          newRecord
            ? { ...data }
            : {
                ...data,
                record_type: recordType,
                record_id: recordId,
                file_type: fileType
              }
        }
        itemRender={(originNode, file) => (
          <div className={imgBlock}>
            {originNode}
            {showCodeButton && (
              <Button
                size="small"
                onClick={() => handleClickAddToCode(file.blobId)}
              >
                Add to code
              </Button>
            )}
          </div>
        )}
      >
        {listType === 'picture-card'
          ? (
              uploadButton
            )
          : (
            <Button icon={selectIcon(status)}>{fm('btn.upload-file')}</Button>
            )}
      </Upload>
      {listType === 'picture-card' && (
        <Modal
          open={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      )}
    </div>
  )
}

PicturesWall.defaultProps = {
  fileType: 'files',
  listType: 'picture-card',
  onlyButton: false,
  multiple: true,
  data: {}
}
