import { Switch } from 'react-router-dom'
import { LayoutScreen } from '../screens/LayoutScreen'
import { LoginRoute } from './LoginRoute'
import { useSelector } from 'react-redux'
import { LoginScreen } from '../screens/LoginScreen'
import { PrivateRoute } from './PrivateRoute'
import { useErrorHook } from '../helpers/hooks/useErrorHook'

export const RootRoute = () => {
  const { isAuthenticated } = useSelector((state) => state.auth)
  useErrorHook()
  return (
    <>
      <Switch>
        <LoginRoute
          isAuthenticated={isAuthenticated}
          path="/login"
          component={LoginScreen}
        />
        <PrivateRoute
          allow
          isAuthenticated={isAuthenticated}
          component={LayoutScreen}
          path="/"
        />
      </Switch>
    </>
  )
}
