import { Col, Form, Input, Radio, Select } from 'antd'
import { fm } from '../../../../lang'
import { useSelector } from 'react-redux'
import { TranslationTabs } from '../../../Shared/Translations/TranslationTabs'
import { emptyComponentTranslation } from '../../../../data/reducers/blocksReducer'

const { TextArea } = Input

export const CollectionModalForm = () => {
  return (
    <Col xs={24}>
      <Form.Item name="collectionStyle" label="Style">
        <Radio.Group initialValue="first" buttonStyle="solid">
          <Radio.Button value="first">Style 1</Radio.Button>
          <Radio.Button value="second">Style 2</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate>
        {(form) => {
          const style = form.getFieldValue('collectionStyle')
          return (
            <>
              {style === 'first' && <Style1Form form={form} />}
              {style === 'second' && <Style2Form form={form} />}
            </>
          )
        }}
      </Form.Item>
    </Col>
  )
}

const Style1Form = ({ form }) => {
  const products = useSelector((state) => state.products.entries)
  const blocks = useSelector((state) => state.blocks.entries).filter(
    (el) => !el.system
  )
  return (
    <>
      <Form.Item label={fm('label.products')} name="productIds">
        <Select
          placeholder="Please select products"
          mode="multiple"
          allowClear
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())}
        >
          {products.map((el) => (
            <Select.Option key={el.id} value={el.id}>
              {el.translations.find(({ locale }) => locale === 'en').name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="viewType" label="View type">
        <Radio.Group initialValue="first" buttonStyle="solid">
          <Radio.Button value="first">First</Radio.Button>
          <Radio.Button value="second">Second</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={fm('label.blocks')} name="blockIds">
        <Select placeholder="Please select blocks" mode="multiple" allowClear>
          {blocks.map((el) => (
            <Select.Option key={el.id} value={el.id}>
              {el.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <TranslationTabs form={form} emptyTranslation={emptyComponentTranslation} fieldName="textContent">
        {({ namespace, translation }) =>
          <>
            <Form.Item
              label="Title"
              name={[namespace, 'title']}
              required={translation.locale === 'en'}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Features"
              name={[namespace, 'features']}
              required={translation.locale === 'en'}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item
              label="First column text"
              name={[namespace, 'firstColumnText']}
              required={translation.locale === 'en'}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item
              label="Second column text"
              name={[namespace, 'secondColumnText']}
              required={translation.locale === 'en'}
            >
              <TextArea rows={4} />
            </Form.Item>
          </>}
      </TranslationTabs>
    </>
  )
}

const Style2Form = ({ form }) => {
  const products = useSelector((state) => state.products.entries)
  const blocks = useSelector((state) => state.blocks.entries).filter((el) => !el.system)

  return (
    <>
      <Form.Item label={fm('label.products')} name="productId">
        <Select placeholder="Please select a product">
          {products.map((el) => (
            <Select.Option key={el.id} value={el.id}>
              {el.translations.find(({ locale }) => locale === 'en').name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="viewType" label="View type">
        <Radio.Group initialValue="first" buttonStyle="solid">
          <Radio.Button value="first">First</Radio.Button>
          <Radio.Button value="second">Second</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={fm('label.blocks')} name="blockId">
        <Select placeholder="Please select blocks" allowClear>
          {blocks.map((el) => (
            <Select.Option key={el.id} value={el.id}>
              {el.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <TranslationTabs form={form} emptyTranslation={emptyComponentTranslation} fieldName="textContent">
        {({ namespace, translation }) =>
          <>
            <Form.Item
              label="Title"
              name={[namespace, 'title']}
              required={translation.locale === 'en'}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Features"
              name={[namespace, 'features']}
              required={translation.locale === 'en'}
            >
              <TextArea rows={4} />
            </Form.Item>
          </>}
      </TranslationTabs>
    </>
  )
}
