import * as types from '../../constants/actionTypes/uiActionTypes'

const defaultState = {
  errorMessage: '',
  errorTrigger: false
}

export default function uiReducer (state = defaultState, action) {
  switch (action.type) {
    case types.UI_CHANGE_ERROR:
      return {
        ...state,
        errorMessage: action.message,
        errorTrigger: !state.errorTrigger
      }
    case types.ERROR_RECORD_NOT_FOUND:
      return {
        ...state,
        errorMessage: action.message,
        errorTrigger: !state.errorTrigger
      }
    default:
      return state
  }
}
