import { useRef } from 'react'
import { Form, Input, Select } from 'antd'

import { fm } from '../../../../lang'
import { FormActionBox } from '../../../Shared/FormActionBox'
import { useSelector } from 'react-redux'
import { useRecordHook } from '../../../../helpers/hooks/useRecordHook'
import { getBlocksTags } from '../../../../data/selectors/blocks'
import { TranslationTabs } from '../../../Shared/Translations/TranslationTabs'
import { TranslationEditor } from '../../../Shared/Translations/TranslationEditor'
import { emptyTranslation } from '../../../../data/reducers/blocksReducer'

const { Option } = Select
export const EditorBlock = ({ form, onFinish, onDelete, isLoading }) => {
  const editorRefs = useRef([])
  const blockTags = useSelector(getBlocksTags)
  const tags = []
  blockTags.forEach((tag) => {
    tags.push(<Option key={tag}>{tag}</Option>)
  })
  const { newRecord } = useRecordHook()
  const onFinishWithEditor = async () => {
    for (let i = 0; i < editorRefs.current.length; i++) {
      const ref = editorRefs.current[i]
      const data = ref.current && ref.current.save ? await ref.current.save() : null

      if (data) {
        form.setFields([{
          name: ['translations', i, 'editor'],
          value: data
        }])
      }
    }

    onFinish()
  }
  return (
    <Form form={form} layout="vertical" onFinish={onFinishWithEditor}>
      <Form.Item
        name="name"
        label={fm('label.name')}
        rules={[
          {
            required: true,
            message: 'Please input name'
          }
        ]}
      >
        <Input style={{ width: 400 }} />
      </Form.Item>
      <Form.Item name="tags" label={fm('label.tags')}>
        <Select mode="tags" style={{ width: 400 }}>
          {tags}
        </Select>
      </Form.Item>
      <TranslationTabs form={form} emptyTranslation={emptyTranslation}>
        {
          ({ namespace, translation }) => {
            if (!editorRefs.current[namespace]) {
              editorRefs.current[namespace] = { current: null }
            }

            return (
              <TranslationEditor
                instanceRef={editorRefs.current[namespace]}
                data={translation.editor}
                uploadPath={`/api/v1/files/blocks?block_id=${form.getFieldValue('id')}`}
                entryId={form.getFieldValue('id')}
                namespace={namespace}
                pluginList={[
                  'headerText',
                  'pageHeader',
                  'list',
                  'simpleImage',
                  'table'
                ]}
              />
            )
          }
        }
      </TranslationTabs>
      <FormActionBox
        isLoading={isLoading}
        newRecord={newRecord}
        onDelete={onDelete}
      />
    </Form>
  )
}
