import { useCallback, useEffect, useState } from 'react'
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Select,
  DatePicker,
  Checkbox,
  Typography,
  notification
} from 'antd'
import { css } from '@linaria/core'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import { fm } from '../../lang'
import {
  createVoucher,
  updateVoucher,
  deleteVoucher,
  selectVoucher,
  resetVoucher
} from '../../data/actions/vouchers'
import { useRecordHook } from '../../helpers/hooks/useRecordHook'
import { FormActionBox } from '../Shared/FormActionBox'
import { usePush } from '../../data/actions/routes'
import { useBreadcrumb } from '../../helpers/hooks/useBreadcrumb'
import { onKeyDownForm } from '../../helpers/form'
import { showError } from '../../data/actions/ui'
import { ProductSelect } from '../Shared/ProductSelect'

const { RangePicker } = DatePicker
const { Option } = Select

const formClass = css`
  width: 900px;
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

const rangeConfig = {
  rules: [
    {
      type: 'array',
      required: true,
      message: 'Please select date'
    }
  ]
}
const initialFormValues = { values: {}, errors: [] }

export const VoucherForm = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)
  const push = usePush()
  const { newRecord, params } = useRecordHook()
  const { id } = params
  const shops = useSelector((state) => state.shops.entries)
  const { entry } = useSelector((state) => state.vouchers)
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(initialFormValues)
  const [form] = Form.useForm()
  const handleBack = useCallback(() => {
    push('/vouchers')
  }, [push])

  useEffect(() => {
    if (!isLoading) {
      if (entry === undefined) {
        showError(dispatch, 'record-not-found')
        resetVoucher(dispatch)
        handleBack()
      } else {
        form.setFieldsValue({
          ...entry,
          activeDate: [entry.startAt && moment(entry.startAt), entry.endAt && moment(entry.endAt)]
        })
        form.setFieldsValue(formValues.values)
        form.setFields(formValues.errors)
      }
    }
  }, [entry, dispatch, form, formValues, handleBack, isLoading])
  useEffect(() => {
    if (!isLoading && !newRecord) {
      selectVoucher(dispatch, id)
    } else if (newRecord) {
      resetVoucher(dispatch)
    }
  }, [isLoading, dispatch, id, newRecord])

  const onFinish = useCallback(async () => {
    const values = form.getFieldsValue(true)

    const productIds = values.productIds.flatMap((id) => {
      const ids = id.split(',')
      return ids.length > 1 ? ids.splice(1) : ids
    })
    const [startAt, endAt] = values.activeDate

    try {
      setFormValues({ values, errors: formValues.errors.map(({ name }) => ({ name, errors: [] })) })

      if (newRecord) {
        const createParams = { ...entry, ...values, productIds, startAt, endAt }
        await createVoucher(dispatch, createParams)
        push(`/vouchers/${createParams.id}`)
        notification.success({ message: 'Voucher successfully created' })
      } else {
        const updateParams = { id, ...values, productIds, startAt, endAt }
        await updateVoucher(dispatch, updateParams)
        notification.success({ message: 'Voucher successfully updated' })
      }
    } catch (e) {
      const errors = e.action.reason.map(({ path, message }) => (
        {
          name: path,
          errors: [message]
        }
      ))

      setFormValues({ values, errors })
    }
  }, [dispatch, entry, form, id, newRecord, push])
  const deleteRecord = useCallback(() => {
    deleteVoucher(dispatch, id, push)
  }, [id, push, dispatch])
  const cardTitle = newRecord ? 'new' : 'edit'
  const voucherList = [
    { value: 'fixed', label: 'Fixed amount' },
    { value: 'percentage', label: 'Percentage' }
  ]

  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm(`voucher.form.${cardTitle}`)}
      onBack={handleBack}
    >
      <Row>
        <Col xs={24}>
          <Form
            form={form}
            layout="vertical"
            className={formClass}
            onFinish={onFinish}
            onKeyDown={onKeyDownForm}
          >
            <Row gutter={[16, 16]}>
              <Col xs={12}>
                <Card
                  size="small"
                  loading={isLoading}
                  title={fm('product.basic')}
                >
                  <Form.Item
                    name="name"
                    label={fm('label.name')}
                    rules={[
                      {
                        required: true,
                        message: 'Please input name'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="code"
                    label={fm('label.code')}
                    rules={[
                      {
                        required: true,
                        message: 'Please input code'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="discountType"
                    label={fm('label.voucher-type')}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please select type'
                      }
                    ]}
                  >
                    <Select placeholder="Please select a voucher type">
                      {voucherList.map((l) => (
                        <Option key={l.value} value={l.value}>
                          {l.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="value"
                    label={fm('label.value')}
                    rules={[
                      {
                        required: true,
                        message: 'Please input value'
                      }
                    ]}
                  >
                    <InputNumber
                      min={0}
                      step={1}
                      style={{ width: '100%' }}
                      precision={2}
                      stringMode
                    />
                  </Form.Item>
                  <Form.Item
                    name="productIds"
                    label={fm('label.products')}
                    hasFeedback
                  >
                    <ProductSelect />
                  </Form.Item>
                  <Typography.Paragraph type="secondary">
                    If left empty - applicable to all products.
                  </Typography.Paragraph>
                  <Form.Item
                    name="shopIds"
                    label={fm('label.shops')}
                    hasFeedback
                  >
                    <Select
                      placeholder="Please select a shop"
                      mode="multiple"
                    >
                      {shops.map((l) => (
                        <Option key={l.id} value={l.id}>
                          {l.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Typography.Paragraph type="secondary">
                    If left empty - applicable to all shops.
                  </Typography.Paragraph>
                  <Form.Item
                    name="activeDate"
                    label={fm('label.active-date')}
                    {...rangeConfig}
                  >
                    <RangePicker showTime />
                  </Form.Item>
                </Card>
              </Col>
              <Col xs={12}>
                <Card
                  size="small"
                  loading={isLoading}
                  title="Limits"
                >
                  <Form.Item
                    name="minQty"
                    label={fm('label.min-quantity')}
                  >
                    <InputNumber
                      min={0}
                      step={1}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="minAmount"
                    label={fm('label.min-amount')}
                  >
                    <InputNumber
                      min={0}
                      step={1}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="maxUsages"
                    label={fm('label.max-usages')}
                  >
                    <InputNumber
                      min={1}
                      step={1}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="applicableWithDiscount"
                    valuePropName="checked"
                  >
                    <Checkbox>
                      {fm('label.applicable-with-discount')}
                    </Checkbox>
                  </Form.Item>
                </Card>
              </Col>
              <FormActionBox
                isLoading={isLoading}
                newRecord={newRecord}
                onDelete={deleteRecord}
              />
            </Row>
          </Form>
        </Col>
      </Row>
    </PageHeader>
  )
}
