import { useCallback, useEffect } from 'react'
import { Card, Row, Col, Form, Input, Alert, PageHeader } from 'antd'
import { css } from '@linaria/core'
import { useDispatch, useSelector } from 'react-redux'

import { fm } from '../../../lang'
import {
  createRole,
  deleteRole,
  resetRole,
  selectRole,
  updateRole
} from '../../../data/actions/roles'
import { useRecordHook } from '../../../helpers/hooks/useRecordHook'
import { FormActionBox } from '../../Shared/FormActionBox'
import { usePush } from '../../../data/actions/routes'
import { PermissionsTree } from './PermissionsTree'
import { showError } from '../../../data/actions/ui'
import { useBreadcrumb } from '../../../helpers/hooks/useBreadcrumb'
import { onKeyDownForm } from '../../../helpers/form'

const tableWrapper = css`
  width: 432px;
  .ant-card-body {
    padding: 16px;
  }
`

const formClass = css`
  width: 900px;
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

export const RoleForm = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)
  const push = usePush()
  const { newRecord, params } = useRecordHook()
  const { id } = params
  const { entry } = useSelector((state) => state.roles)
  const system = entry ? entry.system : false
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const handleBack = useCallback(() => {
    push('/settings/roles')
  }, [push])
  useEffect(() => {
    if (!isLoading) {
      if (entry === undefined) {
        showError(dispatch, 'record-not-found')
        resetRole(dispatch)
        handleBack()
      } else {
        form.setFieldsValue(entry)
      }
    }
  }, [entry])
  const onFinish = useCallback(() => {
    const values = form.getFieldsValue(true)
    if (newRecord) {
      createRole(dispatch, { ...entry, ...values }, push)
    } else {
      updateRole(dispatch, { id, ...values }, push)
    }
  }, [newRecord, id, dispatch])
  const deleteRecord = useCallback(() => {
    deleteRole(dispatch, id, push)
  }, [id, push, dispatch])
  useEffect(() => {
    if (!isLoading && !newRecord) {
      selectRole(dispatch, id)
    } else if (newRecord) {
      resetRole(dispatch)
    }
  }, [isLoading, dispatch, selectRole, resetRole])
  const cardTitle = newRecord ? 'new' : 'edit'
  const setPermissionsKeys = (values) => {
    form.setFieldsValue({ permissionsKeys: values })
  }
  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm(`role.form.${cardTitle}`)}
      onBack={handleBack}
    >
      <Row>
        <Col xs={24}>
          <Row gutter={[0, 16]}>
            {system && (
              <Col xs={24}>
                <Alert
                  message={fm('alert.system-role')}
                  type="warning"
                  showIcon
                />
              </Col>
            )}
            <Col xs={24}>
              <Form
                form={form}
                layout="vertical"
                className={formClass}
                onFinish={onFinish}
                onKeyDown={onKeyDownForm}
              >
                <Row gutter={16}>
                  <Col xs={12}>
                    <Card
                      size="small"
                      className={tableWrapper}
                      loading={isLoading}
                      title={fm('title.permissions')}
                    >
                      <Form.Item shouldUpdate>
                        {(e) => {
                          return (
                            <PermissionsTree
                              values={e.getFieldValue('permissionsKeys')}
                              readOnly={system}
                              setValues={setPermissionsKeys}
                            />
                          )
                        }}
                      </Form.Item>
                    </Card>
                  </Col>
                  <Col xs={12}>
                    <Card
                      size="small"
                      className={tableWrapper}
                      loading={isLoading}
                      title={fm('title.basic-properties')}
                    >
                      <Form.Item
                        name="name"
                        label={fm('label.name')}
                        rules={[
                          {
                            required: !system,
                            message: 'Please input name'
                          }
                        ]}
                      >
                        <Input disabled={system} readOnly={system} />
                      </Form.Item>
                    </Card>
                  </Col>
                </Row>
                {!system && (
                  <FormActionBox
                    isLoading={isLoading}
                    newRecord={newRecord}
                    onDelete={deleteRecord}
                  />
                )}
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </PageHeader>
  )
}
