import { useCallback, useEffect, useRef, useState } from 'react'
import {
  Card,
  Row,
  Col,
  Form,
  PageHeader,
  Tabs,
  Checkbox,
  DatePicker,
  Select,
  notification
} from 'antd'
import moment from 'moment'
import { css } from '@linaria/core'
import { useDispatch, useSelector } from 'react-redux'

import { fm } from '../../../lang'
import {
  createArticle,
  deleteArticle,
  resetArticle,
  selectArticle,
  updateArticle
} from '../../../data/actions/articles'
import { useRecordHook } from '../../../helpers/hooks/useRecordHook'
import { FormActionBox } from '../../Shared/FormActionBox'
import { usePush } from '../../../data/actions/routes'
import { useBreadcrumb } from '../../../helpers/hooks/useBreadcrumb'
import { onKeyDownForm } from '../../../helpers/form'
import { showError } from '../../../data/actions/ui'
import { TranslationTabs } from '../../Shared/Translations/TranslationTabs'
import { TranslationEditor } from '../../Shared/Translations/TranslationEditor'
import { TranslationFields } from './TranslationFields'
import { emptyTranslation } from '../../../data/reducers/articlesReducer'

const formClass = css`
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

const initialFormValues = { values: {}, errors: [] }

export const ArticleForm = ({ isLoading, path }) => {
  const editorRefs = useRef([])
  const breadcrumb = useBreadcrumb(path)
  const push = usePush()
  const { newRecord, params } = useRecordHook()
  const { id } = params
  const { entry } = useSelector((state) => state.articles)
  const websites = useSelector((state) => state.websites.entries)
  const tags = useSelector((state) => state.tags.entries)
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(initialFormValues)
  const [form] = Form.useForm()
  const handleBack = useCallback(() => {
    push('/content/articles')
  }, [push])
  useEffect(() => {
    if (!isLoading) {
      if (entry === undefined) {
        showError(dispatch, 'record-not-found')
        resetArticle(dispatch)
        handleBack()
      } else {
        form.setFieldsValue({
          ...entry,
          publishedAt: entry.publishedAt ? moment(entry.publishedAt) : null
        })
        form.setFieldsValue(formValues.values)
        form.setFields(formValues.errors)
      }
    }
  }, [entry, dispatch, form, handleBack, isLoading, formValues])
  const onFinish = async () => {
    for (let i = 0; i < editorRefs.current.length; i++) {
      const ref = editorRefs.current[i]
      const data = ref.current && ref.current.save ? await ref.current.save() : null

      if (data) {
        form.setFields([{
          name: ['translations', i, 'editor'],
          value: data
        }])
      }
    }

    const values = form.getFieldsValue(true)

    try {
      setFormValues({ values, errors: formValues.errors.map(({ name }) => ({ name, errors: [] })) })

      if (newRecord) {
        const createParams = { ...entry, ...values, publishedAt: values.publishedAt.format('YYYY-MM-DD') }
        await createArticle(dispatch, createParams)
        push(`/content/articles/${createParams.id}`)
        notification.success({ message: 'Article successfully created' })
      } else {
        const updateParams = { id, ...values, publishedAt: values.publishedAt.format('YYYY-MM-DD') }
        await updateArticle(dispatch, updateParams)
        notification.success({ message: 'Article successfully updated' })
      }
    } catch (e) {
      const errors = e.action.reason.map(({ path, message }) => (
        {
          name: path,
          errors: [message]
        }
      ))

      setFormValues({ values, errors })
    }
  }
  const deleteRecord = useCallback(() => {
    deleteArticle(dispatch, id, push)
  }, [id, push, dispatch])
  useEffect(() => {
    if (!isLoading && !newRecord) {
      selectArticle(dispatch, id)
    } else if (newRecord) {
      resetArticle(dispatch)
    }
  }, [isLoading, dispatch, id, newRecord])
  const cardTitle = newRecord ? 'new' : 'edit'

  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm(`article.form.${cardTitle}`)}
      onBack={handleBack}
    >
      <Form
        form={form}
        layout="vertical"
        className={formClass}
        onFinish={onFinish}
        onKeyDown={onKeyDownForm}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Tabs
              defaultActiveKey="basic"
              items={[{
                key: 'basic',
                label: fm('tab.basic'),
                children: (
                  <Row gutter={16}>
                    <Col span={8}>
                      <Card
                        size="small"
                        loading={isLoading}
                        title="Basic"
                      >
                        <Form.Item
                          name="publishedAt"
                          label={fm('label.published-at')}
                          rules={[
                            {
                              required: true,
                              message: 'Please select date'
                            }
                          ]}
                        >
                          <DatePicker />
                        </Form.Item>
                        <Form.Item
                          name="websiteIds"
                          label={fm('label.websites')}
                          rules={[
                            {
                              required: true,
                              message: 'Please choose websites'
                            }
                          ]}
                          hasFeedback
                        >
                          <Select
                            placeholder="Please select websites"
                            mode="multiple"
                          >
                            {websites.map((website) => (
                              <Select.Option key={website.id} value={website.id}>
                                {website.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="tagIds"
                          label={fm('label.tags')}
                          rules={[
                            {
                              required: true,
                              message: 'Please choose tags'
                            }
                          ]}
                          hasFeedback
                        >
                          <Select
                            placeholder="Please select tags"
                            mode="multiple"
                          >
                            {tags.map((tag) => (
                              <Select.Option key={tag.id} value={tag.id}>
                                {tag.translations.find(({ locale }) => locale === 'en').name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="published"
                          valuePropName="checked"
                        >
                          <Checkbox>{fm('label.published')}</Checkbox>
                        </Form.Item>
                      </Card>
                    </Col>
                    <Col span={16}>
                      <Card size="small" loading={isLoading}>
                        <TranslationTabs form={form} emptyTranslation={emptyTranslation}>
                          {({ namespace, translation }) =>
                            <TranslationFields
                              namespace={namespace}
                              locale={translation.locale}
                            />}
                        </TranslationTabs>
                      </Card>
                    </Col>
                  </Row>
                )
              }, {
                key: 'content',
                label: fm('tab.content'),
                children: (
                  <Card size="small" loading={isLoading}>
                    <TranslationTabs form={form} emptyTranslation={emptyTranslation}>
                      {
                        ({ namespace, translation }) => {
                          if (!editorRefs.current[namespace]) {
                            editorRefs.current[namespace] = { current: null }
                          }

                          return (
                            <TranslationEditor
                              instanceRef={editorRefs.current[namespace]}
                              data={translation.editor}
                              uploadPath={`/api/v1/files/article?article_id=${form.getFieldValue('id')}`}
                              entryId={form.getFieldValue('id')}
                              namespace={namespace}
                              pluginList={[
                                'mainImage',
                                'simpleImage',
                                'doubleImage',
                                'avatarImage',
                                'leadText',
                                'headerText',
                                'quoteText',
                                'htmlBlock'
                              ]}
                            />
                          )
                        }
                      }
                    </TranslationTabs>
                  </Card>
                )
              }]}
            />
          </Col>
        </Row>
        <FormActionBox
          isLoading={isLoading}
          newRecord={newRecord}
          onDelete={deleteRecord}
        />
      </Form>
    </PageHeader>
  )
}
