import { PageHeader } from 'antd'
import { useBreadcrumb } from '../helpers/hooks/useBreadcrumb'

export const DashboardScreen = ({ path }) => {
  const breadcrumb = useBreadcrumb(path)

  return (
    <PageHeader breadcrumb={breadcrumb} />
  )
}
