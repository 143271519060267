import { useCallback, useEffect, useState } from 'react'
import {
  Card,
  Table,
  Button,
  PageHeader,
  Dropdown,
  Tag,
  Col,
  Row
} from 'antd'
import {
  Html5Outlined,
  GroupOutlined,
  BarsOutlined,
  PicLeftOutlined,
  DownOutlined
} from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { fm } from '../../../lang'
import { usePush } from '../../../data/actions/routes'
import { useAllow } from '../../../helpers/hooks/usePermissions'
import { useBreadcrumb } from '../../../helpers/hooks/useBreadcrumb'
import { BlocksTableHeader } from './BlocksTableHeader'
import { getFilteredBlocks } from '../../../data/selectors/blocks'

const columns = (pages) => ([
  {
    title: fm('table.name'),
    key: 'name',
    dataIndex: 'name'
  },
  {
    title: fm('table.kind'),
    key: 'kind',
    dataIndex: 'kind',
    width: 100
  },
  {
    title: fm('table.tags'),
    key: 'tags',
    dataIndex: 'tags',
    render: (tags) =>
      tags.map((tag) => {
        return <Tag key={tag}>{tag}</Tag>
      })
  },
  {
    title: fm('table.pages-count'),
    key: 'pagesCount',
    dataIndex: 'pagesCount',
    render: (_, { id }) =>
      pages.filter(({ components }) => components.find((component) => component.blockableId === id)).length
  }
])

const addMenuItems = [
  { key: 'html', icon: <Html5Outlined style={{ marginRight: 8 }} />, label: fm('blocks.table.menu.html') },
  { key: 'collection', icon: <GroupOutlined style={{ marginRight: 8 }} />, label: fm('blocks.table.menu.collection') },
  { key: 'editor', icon: <BarsOutlined style={{ marginRight: 8 }} />, label: fm('blocks.table.menu.editor') },
  { key: 'articles', icon: <PicLeftOutlined style={{ marginRight: 8 }} />, label: fm('blocks.table.menu.articles') },
  { key: 'compare', icon: <PicLeftOutlined style={{ marginRight: 8 }} />, label: fm('blocks.table.menu.compare') }
]

export const BlocksTable = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)
  const allow = useAllow()
  const push = usePush()
  const entries = useSelector(getFilteredBlocks)
  const { entries: pages } = useSelector((state) => state.pages)
  const handleClickAdd = ({ key }) => {
    push(`/content/blocks/new?kind=${key}`)
  }
  const handleClickRow = useCallback(
    (recordId) => {
      push(`/content/blocks/${recordId}`)
    },
    [push]
  )
  const [current, setCurrent] = useState(1)
  const location = useLocation()
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const page = params.get('page')
    if (page && current !== parseInt(page)) {
      setCurrent(parseInt(page))
    }
  }, [])
  const onChange = (values) => {
    const { current } = values
    setCurrent(current)
    push(`/content/blocks?page=${current}`)
  }
  const updatePage = useCallback(
    (newPage) => {
      setCurrent(newPage)
      push(`/content/blocks?page=${newPage}`)
    },
    [setCurrent, push]
  )
  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm('blocks.title')}
      extra={
        allow('can_create_block') && (
          <Dropdown menu={{ items: addMenuItems, onClick: handleClickAdd }}>
            <Button>
              {fm('button.add')} <DownOutlined />
            </Button>
          </Dropdown>
        )
      }
    >
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <BlocksTableHeader updatePage={updatePage} />
        </Col>
        <Col xs={24}>
          <Card size="small">
            <Table
              onChange={onChange}
              onRow={(record) => {
                return {
                  onClick: () => handleClickRow(record.id)
                }
              }}
              dataSource={entries.filter(
                (el) => !el.system || el.kind === 'header'
              )}
              columns={columns(pages)}
              size="small"
              loading={isLoading}
              rowKey={(record) => record.id}
              pagination={{ current }}
            />
          </Card>
        </Col>
      </Row>
    </PageHeader>
  )
}
