import * as types from '../../constants/actionTypes/accountActionTypes'
import { getAccount, setAccount } from '../actions/account'

const emptyAccount = {
  firstName: '',
  lastName: '',
  password: null,
  confirm: null
}
const savedAccount = getAccount()
const defaultState = {
  entry: savedAccount || emptyAccount
}

export default function accountReducer (state = defaultState, action) {
  switch (action.type) {
    case types.ACCOUNT_UPDATE:
      return {
        ...state,
        entry: action.account
      }
    case types.ACCOUNT_FETCH: {
      setAccount(action.data)
      return { ...state, entry: action.data }
    }
    default:
      return state
  }
}
