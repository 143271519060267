import { Route, Redirect } from 'react-router-dom'

export const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      rest.isAuthenticated
        ? (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        : (
          <Component {...props} />
          )}
  />
)
