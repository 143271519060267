import { nanoid } from 'nanoid'
import { RAILS_SERVER } from '../../../constants/api'

export const BlockPreview = ({ item }) => {
  const url = `${RAILS_SERVER}/api/v1/blocks/${item.id}`
  const iframe = `<iframe src="${url}" width="100%" height="auto"></iframe>`
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: iframe }} key={nanoid()} />
    </>
  )
}
