import { Button, Form, Modal } from 'antd'

import { fm } from '../../../lang'
import { css } from '@linaria/core'
import { AddressFields } from '../../Shared/Inputs/AddressFields'

const modalClass = css`
  .ant-modal-body {
    padding: 0 24px;
  }
`

const formClass = css`
  margin-top: 8px;
  width: 100%;
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

const emptyAddress = {
  fullName: '',
  companyName: '',
  phoneNumber: '',
  address: '',
  address2: '',
  city: '',
  countryId: '',
  stateName: '',
  postalCode: '',
  id: null,
  newRecord: true
}

export const AddressModal = ({
  modalOpen,
  setModalOpen,
  createAddress,
  updateAddress,
  form
}) => {
  const showModal = () => {
    form.setFieldsValue(emptyAddress)
    setModalOpen(true)
  }
  const handleOk = () => {
    const values = form.getFieldsValue(true)
    if (values.newRecord) {
      createAddress(values)
    } else {
      updateAddress(values)
    }
    setModalOpen(false)
  }
  const handleCancel = () => {
    setModalOpen(false)
  }
  const newRecord = form.getFieldValue('newRecord')
  const btnOk = newRecord ? 'add' : 'update'
  return (
    <>
      <Button type="default" onClick={showModal} size="small">
        {fm('button.add-address')}
      </Button>
      <Modal
        title={fm('modal.title.new-address')}
        className={modalClass}
        open={modalOpen}
        okText={fm(`button.${btnOk}`)}
        cancelText={fm('button.cancel')}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical" className={formClass}>
          <AddressFields />
        </Form>
      </Modal>
    </>
  )
}
