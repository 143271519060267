import { useEffect, useState } from 'react'
import List from '@editorjs/list'
import Table from '@editorjs/table'
import EditorJs from 'react-editor-js'
import { useSelector } from 'react-redux'

import MainImage from './plugins/main-image'
import SimpleImage from './plugins/simple-image'
import DoubleImage from './plugins/double-image'
import AvatarImage from './plugins/avatar-image'
import LeadText from './plugins/lead-text'
import HeaderText from './plugins/header-text'
import QuoteText from './plugins/quote-text'
import HtmlBlock from './plugins/html-block'
import ProductBlock from './plugins/html-block/product'
import PageHeader from './plugins/page-header'
import VimeoBlock from './plugins/vimeo'
import SimpleCarousel from './plugins/carousel'
import HeaderProduct from './plugins/header-product'
import ProductBasicImage from './plugins/product-basic-image'
import ProductAboutCategory from './plugins/product-about-category'
import ProductDoubleImage from './plugins/product-double-image'

export const EditorComponent = ({
  instanceRef,
  data,
  uploadUrl,
  pluginList,
  entryId
}) => {
  const [id, setId] = useState(null)
  useEffect(() => {
    setId(entryId)
  }, [entryId])
  const blocks = useSelector((state) => state.blocks.entries)
  const toolsList = {
    mainImage: {
      class: MainImage,
      config: {
        endpoints: {
          byFile: uploadUrl
        }
      }
    },
    table: Table,
    simpleImage: {
      class: SimpleImage,
      config: {
        endpoints: {
          byFile: uploadUrl
        }
      }
    },
    doubleImage: {
      class: DoubleImage,
      config: {
        endpoints: {
          byFile: uploadUrl
        }
      }
    },
    productDoubleImage: {
      class: ProductDoubleImage,
      config: {
        endpoints: {
          byFile: uploadUrl
        }
      }
    },
    avatarImage: {
      class: AvatarImage,
      config: {
        endpoints: {
          byFile: uploadUrl
        }
      },
      inlineToolbar: ['link']
    },
    leadText: {
      class: LeadText
    },
    pageHeader: {
      class: PageHeader
    },
    headerText: {
      class: HeaderText
    },
    headerProduct: {
      class: HeaderProduct
    },
    quoteText: {
      class: QuoteText,
      inlineToolbar: ['bold', 'italic']
    },
    carousel: {
      class: SimpleCarousel,
      config: {
        endpoints: {
          byFile: uploadUrl
        }
      }
    },
    htmlBlock: {
      class: HtmlBlock,
      config: {
        options: blocks.filter((el) => !el.system)
      }
    },
    productBlock: {
      class: ProductBlock,
      config: {
        options: blocks.filter(
          (el) => el.system && el.cmsShowRules.includes('productEditor')
        )
      }
    },
    productAboutCategory: {
      class: ProductAboutCategory,
      config: {
        endpoints: {
          byFile: uploadUrl
        }
      },
      inlineToolbar: true
    },
    productBasicImage: {
      class: ProductBasicImage,
      config: {
        endpoints: {
          byFile: uploadUrl
        }
      }
    },
    vimeoBlock: {
      class: VimeoBlock
    },
    list: {
      class: List,
      inlineToolbar: true
    }
  }
  const availableTools = () => {
    const list = {}
    pluginList.forEach((el) => {
      list[el] = toolsList[el]
    })
    return list
  }
  return (
    <EditorJs
      enableReInitialize={id !== entryId}
      instanceRef={(instance) => (instanceRef.current = instance)}
      data={data}
      tools={availableTools()}
    />
  )
}

EditorComponent.defaultProps = {
  pluginList: [
    'mainImage',
    'simpleImage',
    'doubleImage',
    'avatarImage',
    'leadText',
    'headerText',
    'quoteText',
    'htmlBlock'
  ]
}
