import { compose } from 'redux'
import { createStoreCreator } from '@logux/redux'
import { log, confirm, badge, badgeEn, CrossTabClient } from '@logux/client'
import { badgeStyles } from '@logux/client/badge/styles'

import createRootReducer from '../data/reducers'
import { LOGUX_SERVER } from '../constants/api'
import { getToken, setToken } from '../helpers/auth'
import { UI_CHANGE_ERROR } from '../constants/actionTypes/uiActionTypes'
import {
  AUTH_ANONYMOUS,
  AUTH_CHANGE_USER,
  AUTH_ERROR,
  AUTH_SIGN_OUT,
  AUTH_SUCCESS
} from '../constants/actionTypes/authActionTypes'
import { onAuth, authByAnonymous } from './actions/auth'

const ReduxDevToolsMiddleware = () => {
  const ext = '__REDUX_DEVTOOLS_EXTENSION__'
  return window[ext] ? window[ext]() : (f) => f
}

const configureStore = () => {
  const tokens = getToken()
  const client = new CrossTabClient({
    subprotocol: '1.0.0',
    allowDangerousProtocol: import.meta.env.DEV,
    server: LOGUX_SERVER,
    userId:
      tokens && tokens.userId ? tokens.userId.toString() : 'anonymous',
    token: tokens ? tokens.token : ''
  })
  const enhancers = [ReduxDevToolsMiddleware()]
  const composedEnhancers = compose(...enhancers)
  const createStore = createStoreCreator(client)
  return createStore(createRootReducer(), composedEnhancers)
}

const store = configureStore()
log(store.client)

store.client.on('add', (action) => {
  if (action.type === 'logux/undo') {
    if (action.reason === 'denied') {
      store.dispatch({
        type: UI_CHANGE_ERROR,
        message: action.reason
      })
    } else {
      if (Array.isArray(action.reason)) {
        action.reason.forEach((el) => {
          store.dispatch({
            type: UI_CHANGE_ERROR,
            message: el.message
          })
        })
      }
    }
  }
  if (action.type === AUTH_ERROR) {
    store.dispatch({
      type: UI_CHANGE_ERROR,
      message: action.message
    })
  }
  if (action.type === AUTH_SUCCESS && store.client.role === 'leader') {
    setToken(action.data)
    store.dispatch.crossTab({ type: AUTH_CHANGE_USER, data: action.data })
  }
  if (action.type === AUTH_SIGN_OUT) {
    if (store.client.role === 'leader') {
      onAuth('anonymous', '').then(() => {})
    } else {
      store.client.options.userId = 'anonymous'
      store.client.options.token = ''
      store.client.clientId = 'anonymous' + ':' + store.client.getClientId()
      store.client.nodeId = store.client.clientId + ':' + store.client.tabId
      store.client.log.nodeId = store.client.nodeId
      store.client.node.localNodeId = store.client.nodeId
      store.client.node.options.token = ''
      store.client.emitter.emit('user', 'anonymous')
    }
  }
  if (action.type === AUTH_CHANGE_USER) {
    const { token, userId } = action.data
    if (store.client.role === 'leader') {
      onAuth(userId.toString(), token).then(() => {
        resubscribeChannels()
      })
    } else {
      store.client.options.userId = userId
      store.client.options.token = token
      store.client.clientId = userId + ':' + store.client.getClientId()
      store.client.nodeId = store.client.clientId + ':' + store.client.tabId
      store.client.log.nodeId = store.client.nodeId
      store.client.node.localNodeId = store.client.nodeId
      store.client.node.options.token = token
      store.client.emitter.emit('user', userId)
    }
  }
})

const subscribers = (entries) => {
  const r = []
  if (entries) {
    for (const key of Object.keys(entries)) {
      r.push(JSON.parse(key).channel)
    }
  }
  return r
}

const resubscribeChannels = () => {
  const channels = subscribers(store.subscribers)
  channels.forEach((c) => {
    store.dispatch.sync({ type: 'logux/unsubscribe', channel: c })
    store.dispatch.sync({ type: 'logux/subscribe', channel: c })
  })
}

// Reconnect by anonymous if credentials is wrong.
window.addEventListener('error', (event) => {
  if (event.error && event.error.type === 'wrong-credentials') {
    // resetAccount();
    // resetToken();
    store.dispatch.crossTab({ type: AUTH_ANONYMOUS })
    authByAnonymous('anonymous', '')
  }
})

store.client.start()
confirm(store.client)
badge(store.client, {
  messages: badgeEn,
  styles: {
    ...badgeStyles,
    synchronized: { backgroundColor: 'green' }
  },
  position: 'bottom-left'
})
export default store
