import { Form, Input, Card, Checkbox, Tooltip } from 'antd'
import { fm } from '../../../lang'

export const BasicFields = ({ isLoading }) => {
  return (
    <Card size="small" title={fm('customer.form.info')} loading={isLoading}>
      <Form.Item
        name="email"
        label={fm('label.email')}
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail'
          },
          {
            required: true,
            message: 'Please input E-mail'
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="fullName"
        label={fm('label.full-name')}
        rules={[
          {
            required: true,
            message: 'Please input full name'
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="agreeSubscribe" valuePropName="checked">
        <Checkbox>{fm('checkbox.agree-to-subscribe')}</Checkbox>
      </Form.Item>
      <Form.Item name="agreeGdpr" valuePropName="agreeGdpr">
        <Tooltip title={fm('tooltip.gdpr-text')}>
          <Checkbox disabled>{fm('checkbox.agree-to-gdpr')}</Checkbox>
        </Tooltip>
      </Form.Item>
    </Card>
  )
}
