import * as types from '../../constants/actionTypes/pagesActionTypes'
import { v4 as uuidv4 } from 'uuid'

export const kinds = ['system', 'custom', 'product', 'support', 'collection']
export const publishedStatuses = ['all', 'published', 'draft']

const emptyTableFilters = {
  term: null,
  kind: null,
  website: null,
  publishedStatus: 'all'
}

export const emptyTranslation = {
  locale: null,
  slug: null,
  title: null,
  seoTitle: null,
  seoKeywords: null,
  seoDescription: null,
  socialTitle: null,
  socialDescription: null
}

const emptyPage = {
  id: null,
  name: '',
  kind: null,
  templateId: null,
  websiteIds: [],
  productIds: [],
  tags: [],
  components: [],
  translations: [{ ...emptyTranslation, locale: 'en' }],
  publishedAt: null
}

const defaultState = {
  entry: { ...emptyPage, id: uuidv4() },
  entries: [],
  requests: { translateFetching: false },
  tableFilters: emptyTableFilters
}

export default function pagesReducer (state = defaultState, action) {
  switch (action.type) {
    case types.PAGES_CREATE:
      return {
        ...state,
        entry: { ...action.page },
        entries: [{ ...action.page }, ...state.entries]
      }
    case types.PAGES_UPDATE:
      return {
        ...state,
        entries: state.entries.map((u) => {
          if (u.id === action.page.id) {
            return { ...action.page }
          } else {
            return u
          }
        })
      }
    case types.PAGES_DELETE:
      return {
        ...state,
        entry: { ...emptyPage, id: uuidv4() },
        entries: state.entries.filter((u) => u.id !== action.page.id)
      }
    case types.PAGE_FETCH:
      return { ...state, entry: action.data }
    case types.PAGE_SELECT:
      return {
        ...state,
        entry: state.entries.find((u) => u.id === action.id) || { ...emptyPage, notFound: true }
      }
    case types.PAGE_FORM_RESET:
      return { ...state, entry: { ...emptyPage, id: uuidv4() } }
    case types.PAGES_FETCH:
      return { ...state, entries: action.data }
    case types.PAGES_AFTER_DESTROY_BLOCK:
      return {
        ...state,
        entry: {
          ...state.entry,
          components: state.entry.components.filter(
            (c) => c.blockableId !== action.id
          )
        },
        entries: state.entries.map((el) => ({
          ...el,
          components: el.components.filter((c) => c.blockableId !== action.id)
        }))
      }
    case types.PAGES_CHANGE_TABLE_FILTER:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          [action.filterType]: action.filterValue
        }
      }
    case types.PAGES_UPDATE_LOCAL:
      return {
        ...state,
        entry: { ...state.entry, [action.valueType]: action.value },
        entries: state.entries.map((u) => {
          if (u.id === action.id) {
            return { ...u, [action.valueType]: action.value }
          } else {
            return u
          }
        })
      }
    case types.PAGES_FETCH_TRANSLATE:
      return {
        ...state,
        requests: { ...state.requests, translateFetching: true }
      }
    case types.PAGES_FETCH_TRANSLATE_SUCCESS:
      return {
        ...state,
        requests: { ...state.requests, translateFetching: false }
      }
    default:
      return state
  }
}
