import { useCallback, useEffect, useState } from 'react'
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  PageHeader,
  Select,
  DatePicker,
  Button,
  Checkbox,
  List,
  Typography,
  notification
} from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { css } from '@linaria/core'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { fm } from '../../lang'
import {
  createFreeForm,
  updateFreeForm,
  deleteFreeForm,
  selectFreeForm,
  resetFreeForm
} from '../../data/actions/freeForms'
import { useRecordHook } from '../../helpers/hooks/useRecordHook'
import { FormActionBox } from '../Shared/FormActionBox'
import { usePush } from '../../data/actions/routes'
import { useBreadcrumb } from '../../helpers/hooks/useBreadcrumb'
import { onKeyDownForm } from '../../helpers/form'
import { showError } from '../../data/actions/ui'
import { emptyFreeFormField } from '../../data/reducers/freeFormsReducer'

const { RangePicker } = DatePicker

const formClass = css`
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

const initialFormValues = { values: {}, errors: [] }

export const FreeFormForm = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)
  const push = usePush()
  const { newRecord, params } = useRecordHook()
  const { id } = params
  const { entry, typeNames } = useSelector((state) => state.freeForms)
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(initialFormValues)
  const [form] = Form.useForm()
  const handleBack = useCallback(() => {
    push('/free_forms')
  }, [push])

  useEffect(() => {
    if (!isLoading) {
      if (entry === undefined) {
        showError(dispatch, 'record-not-found')
        resetFreeForm(dispatch)
        handleBack()
      } else {
        form.setFieldsValue({
          ...entry,
          activeTime: [
            entry.startAt ? moment(entry.startAt) : null,
            entry.endAt ? moment(entry.endAt) : null
          ]
        })
        form.setFieldsValue(formValues.values)
        form.setFields(formValues.errors)
      }
    }
  }, [entry, dispatch, form, formValues, handleBack, isLoading])
  useEffect(() => {
    if (!isLoading && !newRecord) {
      selectFreeForm(dispatch, id)
    } else if (newRecord) {
      resetFreeForm(dispatch)
    }
  }, [isLoading, dispatch, id, newRecord])

  const onFinish = useCallback(async () => {
    const values = form.getFieldsValue(true)
    const startAt = values.activeTime?.[0]?.startOf('minute')?.utc()?.format()
    const endAt = values.activeTime?.[1]?.startOf('minute')?.utc()?.format()

    try {
      setFormValues({ values, errors: formValues.errors.map(({ name }) => ({ name, errors: [] })) })

      if (newRecord) {
        const createParams = { ...entry, ...values, startAt, endAt }
        await createFreeForm(dispatch, createParams)
        push(`/free_forms/${createParams.id}`)
        notification.success({ message: 'FreeForm successfully created' })
      } else {
        const updateParams = { id, ...values, startAt, endAt }
        await updateFreeForm(dispatch, updateParams)
        notification.success({ message: 'FreeForm successfully updated' })
      }
    } catch (e) {
      const errors = e.action.reason.map(({ path, message }) => (
        {
          name: path,
          errors: [message]
        }
      ))

      setFormValues({ values, errors })
    }
  }, [dispatch, entry, form, id, newRecord, push])
  const deleteRecord = useCallback(() => {
    deleteFreeForm(dispatch, id, push)
  }, [id, push, dispatch])
  const cardTitle = newRecord ? 'new' : 'edit'

  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm(`free-form.form.${cardTitle}`)}
      onBack={handleBack}
    >
      <Row>
        <Col xs={24}>
          <Form
            form={form}
            layout="vertical"
            className={formClass}
            onFinish={onFinish}
            onKeyDown={onKeyDownForm}
          >
            <Row gutter={[16, 16]}>
              <Col xs={12}>
                <Card
                  size="small"
                  loading={isLoading}
                  title={fm('free-form.basic')}
                >
                  <Form.Item
                    name="identifier"
                    label={fm('label.identifier')}
                    rules={[
                      {
                        required: true,
                        message: 'Please input identifier'
                      }
                    ]}
                    help="Only numbers, lower letters and _ are allowed"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="activeTime"
                    label={fm('label.active-time')}
                    help="Leave empty if you want the form to be always active"
                  >
                    <RangePicker
                      allowEmpty={[true, true]}
                      showTime={{ format: 'HH:mm' }}
                      format="YYYY-MM-DD HH:mm"
                    />
                  </Form.Item>
                  <Typography.Text
                    copyable
                  >
                    <pre style={{ fontSize: '10px' }}>
                      {entry.html}
                    </pre>
                  </Typography.Text>
                </Card>
              </Col>
              <Col xs={12}>
                <Card
                  size="small"
                  loading={isLoading}
                  title={fm('free-form.fields')}
                >
                  <Form.List name="fields">
                    {(fields, { add, remove }) => (
                      <>
                        <List
                          dataSource={fields}
                          renderItem={(field) => (
                            <List.Item
                              actions={[
                                <Button
                                  key="remove-item"
                                  onClick={() => remove(field.name)}
                                  type="danger"
                                  icon={<DeleteOutlined />}
                                  style={{ zIndex: 100000, marginTop: 16 }}
                                />
                              ]}
                            >
                              <Col xs={20}>
                                <Form.Item
                                  label={fm('label.identifier')}
                                  name={[field.name, 'identifier']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input identifier'
                                    }
                                  ]}
                                  help="Only numbers, lower letters and _ are allowed"
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label={fm('label.type-name')}
                                  name={[field.name, 'typeName']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please select type'
                                    }
                                  ]}
                                >
                                  <Select options={typeNames.map(typeName => ({ label: typeName, value: typeName }))} />
                                </Form.Item>
                                <Form.Item
                                  name={[field.name, 'required']}
                                  valuePropName="checked"
                                >
                                  <Checkbox>
                                    {fm('label.required')}
                                  </Checkbox>
                                </Form.Item>
                              </Col>
                            </List.Item>
                          )}
                        />
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={() => {
                            add({
                              ...emptyFreeFormField,
                              id: uuidv4()
                            })
                          }}
                        >
                          {fm('button.add')}
                        </Button>
                      </>
                    )}
                  </Form.List>
                </Card>
              </Col>
              <FormActionBox
                isLoading={isLoading}
                newRecord={newRecord}
                onDelete={deleteRecord}
              />
            </Row>
          </Form>
        </Col>
      </Row>
    </PageHeader>
  )
}
