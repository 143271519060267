import { useCallback, useEffect, useState } from 'react'
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  PageHeader,
  Select,
  notification
} from 'antd'
import { css } from '@linaria/core'
import { useDispatch, useSelector } from 'react-redux'

import { fm } from '../../../lang'
import {
  createSpecification,
  updateSpecification,
  deleteSpecification,
  resetSpecification,
  selectSpecification
} from '../../../data/actions/specifications'
import { useRecordHook } from '../../../helpers/hooks/useRecordHook'
import { FormActionBox } from '../../Shared/FormActionBox'
import { usePush } from '../../../data/actions/routes'
import { showError } from '../../../data/actions/ui'
import { useBreadcrumb } from '../../../helpers/hooks/useBreadcrumb'
import { onKeyDownForm } from '../../../helpers/form'
import { TranslationTabs } from '../../Shared/Translations/TranslationTabs'
import { emptyTranslation } from '../../../data/reducers/specificationsReducer'

const formClass = css`
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

const initialFormValues = { values: {}, errors: [] }

export const SpecificationForm = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)
  const push = usePush()
  const { newRecord, params } = useRecordHook()
  const { id } = params
  const { entry } = useSelector((state) => state.specifications)
  const specificationGroups = useSelector((state) => state.specificationGroups.entries)
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(initialFormValues)
  const [form] = Form.useForm()
  const handleBack = useCallback(() => {
    push('/assortment/specifications')
  }, [push])
  useEffect(() => {
    if (!isLoading) {
      if (entry === undefined) {
        showError(dispatch, 'record-not-found')
        resetSpecification(dispatch)
        handleBack()
      } else {
        form.setFieldsValue(entry)
        form.setFieldsValue(formValues.values)
        form.setFields(formValues.errors)
      }
    }
  }, [entry, dispatch, form, handleBack, isLoading, formValues])
  const onFinish = useCallback(async () => {
    const values = form.getFieldsValue(true)

    try {
      setFormValues({ values, errors: formValues.errors.map(({ name }) => ({ name, errors: [] })) })

      if (newRecord) {
        const createParams = { ...entry, ...values }
        await createSpecification(dispatch, createParams)
        push(`/assortment/specifications/${createParams.id}`)
        notification.success({ message: 'Specification successfully created' })
      } else {
        const updateParams = { id, ...values }
        await updateSpecification(dispatch, updateParams)
        notification.success({ message: 'Specification successfully updated' })
      }
    } catch (e) {
      const errors = e.action.reason.map(({ path, message }) => (
        {
          name: path,
          errors: [message]
        }
      ))

      setFormValues({ values, errors })
    }
  }, [newRecord, id, dispatch, entry, form, push])
  const deleteRecord = useCallback(() => {
    deleteSpecification(dispatch, id, push)
  }, [id, push, dispatch])
  useEffect(() => {
    if (!isLoading && !newRecord) {
      selectSpecification(dispatch, id)
    } else if (newRecord) {
      resetSpecification(dispatch)
    }
  }, [isLoading, dispatch, id, newRecord])
  const cardTitle = newRecord ? 'new' : 'edit'

  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm(`specification.form.${cardTitle}`)}
      onBack={handleBack}
    >
      <Form
        form={form}
        layout="vertical"
        className={formClass}
        onKeyDown={onKeyDownForm}
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Card size="small" loading={isLoading}>
              <Form.Item
                name="name"
                label={fm('label.name')}
                rules={[
                  {
                    required: true,
                    message: 'Please input name'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={fm('label.group')}
                name="specificationGroupId"
                rules={[
                  {
                    required: true,
                    message: 'Please select a group'
                  }
                ]}
              >
                <Select
                  placeholder="Please select a group"
                  style={{ width: 300 }}
                >
                  {specificationGroups.map((l) => (
                    <Select.Option key={l.id} value={l.id}>
                      {l.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Card>
          </Col>
          <Col span={16}>
            <Card size="small" loading={isLoading}>
              <TranslationTabs form={form} emptyTranslation={emptyTranslation}>
                {({ namespace, translation }) =>
                  <Form.Item
                    label={fm('label.translations.title')}
                    name={[namespace, 'title']}
                    required={translation.locale === 'en'}
                  >
                    <Input />
                  </Form.Item>}
              </TranslationTabs>
            </Card>
          </Col>
        </Row>
        <FormActionBox
          isLoading={isLoading}
          newRecord={newRecord}
          onDelete={deleteRecord}
        />
      </Form>
    </PageHeader>
  )
}
