import Ui from './ui'
import Tunes from './tunes'
import Uploader from './uploader'

export default class ProductBasicImage {
  static get isReadOnlySupported () {
    return true
  }

  static get toolbox () {
    return {
      icon:
        '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150.242V79c0-18.778-15.222-34-34-34H79c-18.778 0-34 15.222-34 34v42.264l67.179-44.192 80.398 71.614 56.686-29.14L291 150.242zm-.345 51.622l-42.3-30.246-56.3 29.884-80.773-66.925L45 174.187V197c0 18.778 15.222 34 34 34h178c17.126 0 31.295-12.663 33.655-29.136zM79 0h178c43.63 0 79 35.37 79 79v118c0 43.63-35.37 79-79 79H79c-43.63 0-79-35.37-79-79V79C0 35.37 35.37 0 79 0z"/></svg>',
      title: 'Simple image and description'
    }
  }

  static get sanitize () {
    return {
      description: {
        b: true,
        a: true,
        br: true,
        div: true
      }
    }
  }

  /**
   * @param {object} tool - tool properties got from editor.js
   * @param {ImageToolData} tool.data - previously saved data
   * @param {ImageConfig} tool.config - user config for Tool
   * @param {object} tool.api - Editor.js API
   * @param {boolean} tool.readOnly - read-only mode flag
   */
  constructor ({ data, config, api, readOnly }) {
    this.api = api
    this.readOnly = readOnly

    /**
     * Tool's initial config
     */
    this.config = {
      endpoints: config.endpoints || '',
      additionalRequestData: config.additionalRequestData || {},
      additionalRequestHeaders: config.additionalRequestHeaders || {},
      field: config.field || 'image',
      types: config.types || 'image/*',
      titlePlaceholder: 'Title',
      altPlaceholder: 'Alt',
      descriptionPlaceholder: 'Description',
      factTextFirstPlaceholder: 'First factoid text',
      factTextSecondPlaceholder: 'Second factoid text',
      factCaptionFirstPlaceholder: 'First factoid caption',
      factCaptionSecondPlaceholder: 'Second factoid caption',
      buttonContent: config.buttonContent || '',
      uploader: config.uploader || undefined,
      actions: config.actions || []
    }

    /**
     * Module for file uploading
     */
    this.uploader = new Uploader({
      config: this.config,
      onUpload: (response) => this.onUpload(response),
      onError: (error) => this.uploadingFailed(error)
    })

    /**
     * Module for working with UI
     */
    this.ui = new Ui({
      api,
      config: this.config,
      onSelectFile: () => {
        this.uploader.uploadSelectedFile({
          onPreview: (src) => {
            this.ui.showPreloader(src)
          }
        })
      },
      readOnly
    })

    /**
     * Module for working with tunes
     */
    this.tunes = new Tunes({
      api,
      actions: this.config.actions,
      onChange: (tuneName) => this.tuneToggled(tuneName)
    })

    /**
     * Set saved state
     */
    this._data = {}
    this.data = data
  }

  /**
   * Renders Block content
   *
   * @public
   *
   * @returns {HTMLDivElement}
   */
  render () {
    return this.ui.render(this.data)
  }

  /**
   * Return Block data
   *
   * @public
   *
   * @returns {ImageToolData}
   */
  save () {
    const title = this.ui.nodes.title
    const alt = this.ui.nodes.alt
    const description = this.ui.nodes.description
    const factTextFirst = this.ui.nodes.factTextFirst
    const factCaptionFirst = this.ui.nodes.factCaptionFirst
    const factTextSecond = this.ui.nodes.factTextSecond
    const factCaptionSecond = this.ui.nodes.factCaptionSecond

    this._data.alt = alt.value
    this._data.title = title.value
    this._data.factTextFirst = factTextFirst.value
    this._data.factCaptionFirst = factCaptionFirst.value
    this._data.factTextSecond = factTextSecond.value
    this._data.factCaptionSecond = factCaptionSecond.value
    this._data.description = description.innerHTML
    return this.data
  }

  /**
   * Makes buttons with tunes: add background, add border, stretch image
   *
   * @public
   *
   * @returns {Element}
   */
  renderSettings () {
    return this.tunes.render(this.data)
  }

  /**
   * Fires after clicks on the Toolbox Image Icon
   * Initiates click on the Select File button
   *
   * @public
   */
  appendCallback () {
    this.ui.nodes.fileButton.click()
  }

  /**
   * Specify paste substitutes
   *
   * @see {@description https://github.com/codex-team/editor.js/blob/master/docs/tools.md#paste-handling}
   * @returns {{tags: string[], patterns: object<string, RegExp>, files: {extensions: string[], mimeTypes: string[]}}}
   */
  static get pasteConfig () {
    return false
  }

  /**
   * Specify paste handlers
   *
   * @public
   * @see {@description https://github.com/codex-team/editor.js/blob/master/docs/tools.md#paste-handling}
   * @param {CustomEvent} event - editor.js custom paste event
   *                              {@description https://github.com/codex-team/editor.js/blob/master/types/tools/paste-events.d.ts}
   * @returns {void}
   */
  async onPaste (event) {
    switch (event.type) {
      case 'tag': {
        const image = event.detail.data

        /** Images from PDF */
        if (/^blob:/.test(image.src)) {
          const response = await fetch(image.src)
          const file = await response.blob()

          this.uploadFile(file)
          break
        }

        this.uploadUrl(image.src)
        break
      }
      case 'pattern': {
        const url = event.detail.data

        this.uploadUrl(url)
        break
      }
      case 'file': {
        const file = event.detail.file

        this.uploadFile(file)
        break
      }
    }
  }

  /**
   * Private methods
   * ̿̿ ̿̿ ̿̿ ̿'̿'\̵͇̿̿\з= ( ▀ ͜͞ʖ▀) =ε/̵͇̿̿/’̿’̿ ̿ ̿̿ ̿̿ ̿̿
   */

  /**
   * Stores all Tool's data
   *
   * @private
   *
   * @param {ImageToolData} data - data in Image Tool format
   */
  set data (data) {
    this.image = data.file

    this._data.title = data.title || ''
    this._data.alt = data.alt || ''
    this._data.description = data.description || ''
    this._data.factTextFirst = data.factTextFirst || ''
    this._data.factCaptionFirst = data.factCaptionFirst || ''
    this._data.factTextSecond = data.factTextSecond || ''
    this._data.factCaptionSecond = data.factCaptionSecond || ''
    this.ui.fillInput('factTextFirst', this._data.factTextFirst)
    this.ui.fillInput('factCaptionFirst', this._data.factCaptionFirst)
    this.ui.fillInput('factTextSecond', this._data.factTextSecond)
    this.ui.fillInput('factCaptionSecond', this._data.factCaptionSecond)
    this.ui.fillInput('title', this._data.title)
    this.ui.fillEditableDiv('description', this._data.description)
    this.ui.fillInput('alt', this._data.alt)

    Tunes.tunes.forEach(({ name: tune }) => {
      const value =
        typeof data[tune] !== 'undefined'
          ? data[tune] === true || data[tune] === 'true'
          : false

      this.setTune(tune, value)
    })
  }

  /**
   * Return Tool data
   *
   * @private
   *
   * @returns {ImageToolData}
   */
  get data () {
    return this._data
  }

  /**
   * Set new image file
   *
   * @private
   *
   * @param {object} file - uploaded file data
   */
  set image (file) {
    this._data.file = file || {}

    if (file && file.url) {
      this.ui.fillImage(file.url)
    }
  }

  /**
   * File uploading callback
   *
   * @private
   *
   * @param {UploadResponseFormat} response - uploading server response
   * @returns {void}
   */
  onUpload (response) {
    if (response.success && response.file) {
      this.image = response.file
    } else {
      this.uploadingFailed('incorrect response: ' + JSON.stringify(response))
    }
  }

  /**
   * Handle uploader errors
   *
   * @private
   * @param {string} errorText - uploading error text
   * @returns {void}
   */
  uploadingFailed (errorText) {
    console.log('Image Tool: uploading failed because of', errorText)

    this.api.notifier.show({
      message: this.api.i18n.t('Couldn’t upload image. Please try another.'),
      style: 'error'
    })
    this.ui.hidePreloader()
  }

  /**
   * Callback fired when Block Tune is activated
   *
   * @private
   *
   * @param {string} tuneName - tune that has been clicked
   * @returns {void}
   */
  tuneToggled (tuneName) {
    // inverse tune state
    this.setTune(tuneName, !this._data[tuneName])
  }

  /**
   * Set one tune
   *
   * @param {string} tuneName - {@description Tunes.tunes}
   * @param {boolean} value - tune state
   * @returns {void}
   */
  setTune (tuneName, value) {
    this._data[tuneName] = value

    this.ui.applyTune(tuneName, value)

    if (tuneName === 'stretched') {
      /**
       * Wait until the API is ready
       */
      Promise.resolve()
        .then(() => {
          const blockId = this.api.blocks.getCurrentBlockIndex()

          this.api.blocks.stretchBlock(blockId, value)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  /**
   * Show preloader and upload image file
   *
   * @param {File} file - file that is currently uploading (from paste)
   * @returns {void}
   */
  uploadFile (file) {
    this.uploader.uploadByFile(file, {
      onPreview: (src) => {
        this.ui.showPreloader(src)
      }
    })
  }

  /**
   * Show preloader and upload image by target url
   *
   * @param {string} url - url pasted
   * @returns {void}
   */
  uploadUrl (url) {
    this.ui.showPreloader(url)
    this.uploader.uploadByUrl(url)
  }
}
