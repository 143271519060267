import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form, Tabs } from 'antd'
import { AddTranslationModal } from './AddTranslationModal'

const onEdit = ({ fields, remove, setModalOpen }) =>
  (targetKey, action) => {
    switch (action) {
      case 'add':
        setModalOpen(true)
        break
      case 'remove':
        remove(fields.find(field => field.key === parseInt(targetKey)).name || parseInt(targetKey))
        break
      default:
    }
  }

export const TranslationTabs = ({ form, emptyTranslation, fieldName = 'translations', children }) => {
  const websites = useSelector((state) => state.websites.entries)
  const websiteLocales = [...new Set(websites.map(({ locale }) => locale))]
  const existingTranslations = form.getFieldValue(fieldName)
  const usedLocales = existingTranslations ? existingTranslations.map(({ locale }) => locale) : []
  const unusedLocales = websiteLocales.filter(locale => !usedLocales.includes(locale))

  const [modalOpen, setModalOpen] = useState(false)
  const onModalCancel = useCallback(() => setModalOpen(false), [setModalOpen])
  const onModalAdd = useCallback(({ add }) =>
    ({ locale }) => {
      add({ ...emptyTranslation, locale })
      setModalOpen(false)
    }
  , [emptyTranslation, setModalOpen])

  return (
    <Form.List name={fieldName}>
      {(fields, { add, remove }) => (
        <>
          <AddTranslationModal
            open={modalOpen}
            onCancel={onModalCancel}
            onAdd={onModalAdd({ add })}
            usedLocales={usedLocales}
          />
          <Tabs
            type="editable-card"
            hideAdd={unusedLocales.length === 0}
            onEdit={onEdit({ fields, remove, setModalOpen })}
            items={
              fields.map(field => {
                const translation = form.getFieldValue([fieldName, field.name])
                const tabWebsites = websites.filter(website => website.locale === translation.locale)
                const websiteNames = tabWebsites.map(({ name }) => name)
                const tabTitle = websiteNames.length > 0 ? websiteNames.join(', ') : '<no website>'

                return {
                  key: field.key,
                  label: `${tabTitle} (${translation.locale})`,
                  closable: !tabWebsites.find(website => website.prefix === ''),
                  children: children({ namespace: field.name, translation })
                }
              })
            }
          />
        </>
      )}
    </Form.List>
  )
}
