import { Form, Input, Select, Row, Col, Card } from 'antd'

import { fm } from '../../../lang'

const { TextArea } = Input

export const TranslationFields = ({ namespace, locale, isLoading }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col xs={12}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Card
              size="small"
              loading={isLoading}
              title="Main"
            >
              <Form.Item
                label={fm('label.translations.slug')}
                name={[namespace, 'slug']}
                required={locale === 'en'}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={fm('label.translations.name')}
                name={[namespace, 'name']}
                required={locale === 'en'}
              >
                <Input />
              </Form.Item>
              <Form.Item shouldUpdate>
                {(f) => {
                  const medias = f.getFieldValue('medias')
                  return (
                    medias && (
                      <Form.Item
                        name={[namespace, 'guideMediaId']}
                        label="User guide"
                      >
                        <Select
                          notFoundContent={null}
                          size="large"
                          allowClear
                        >
                          {medias
                            .filter((el) => el.parentId !== null)
                            .map((el) => (
                              <Select.Option
                                key={el.parentId}
                                value={el.parentId}
                              >
                                <img
                                  src={el.thumbUrl}
                                  width={38}
                                  height={38}
                                />
                                <span>
                                  &nbsp;&nbsp;{el.name}
                                </span>
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    )
                  )
                }}
              </Form.Item>
            </Card>
          </Col>
          <Col xs={24}>
            <Card
              size="small"
              loading={isLoading}
              title="Texts"
            >
              <Form.Item
                label={fm('label.translations.short-text')}
                name={[namespace, 'shortText']}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={fm('label.translations.long-text')}
                name={[namespace, 'longText']}
              >
                <TextArea rows={4} />
              </Form.Item>
              <Form.Item
                label={fm('label.translations.feature')}
                name={[namespace, 'feature']}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={fm('label.translations.details')}
                name={[namespace, 'details']}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Card
          size="small"
          loading={isLoading}
          title="SEO"
        >
          <Form.Item
            label={fm('label.translations.seo-title')}
            name={[namespace, 'seoTitle']}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={fm('label.translations.seo-keywords')}
            name={[namespace, 'seoKeywords']}
          >
            <TextArea rows={2} />
          </Form.Item>
          <Form.Item
            label={fm('label.translations.seo-description')}
            name={[namespace, 'seoDescription']}
          >
            <TextArea rows={2} />
          </Form.Item>
          <Form.Item
            label={fm('label.translations.social-title')}
            name={[namespace, 'socialTitle']}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={fm('label.translations.social-description')}
            name={[namespace, 'socialDescription']}
          >
            <TextArea rows={2} />
          </Form.Item>
        </Card>
      </Col>
    </Row>
  )
}
