import { useEffect, useState } from 'react'
import { Row, Col, Input, Typography } from 'antd'
import { useDebounce } from 'use-debounce'

import { fm } from '../../../lang'
import { useDispatch, useSelector } from 'react-redux'
import { changeFiltersCustomers } from '../../../data/actions/customers'

const { Text } = Typography

export const CustomersTableHeader = () => {
  const dispatch = useDispatch()
  const searchValue = useSelector(
    (state) => state.customers.filtersEntries.term
  )
  const [term, setTerm] = useState(searchValue)
  const [value] = useDebounce(term, 200)
  const onChangeNameOrEmail = (e) => {
    setTerm(e.target.value)
  }
  useEffect(() => {
    changeFiltersCustomers(dispatch, 'term', value)
  }, [dispatch, value])
  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
      <Col xs={6}>
        <Row>
          <Text>{fm('label.enter-name-or-email')}</Text>
          <Input
            allowClear
            onChange={onChangeNameOrEmail}
            defaultValue={term}
          />
        </Row>
      </Col>
    </Row>
  )
}
