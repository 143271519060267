import { useCallback } from 'react'
import { Card, Table, Button, PageHeader } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

import { fm } from '../../../lang'
import { usePush } from '../../../data/actions/routes'
import { useAllow } from '../../../helpers/hooks/usePermissions'
import { useBreadcrumb } from '../../../helpers/hooks/useBreadcrumb'

const columns = [
  {
    title: fm('table.name'),
    key: 'name',
    dataIndex: 'name'
  }
]

export const RolesTable = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)
  const allow = useAllow()
  const push = usePush()
  const { entries } = useSelector((state) => state.roles)
  const handleClickAdd = () => {
    push('/settings/roles/new')
  }
  const handleClickRow = useCallback(
    (recordId) => {
      push(`/settings/roles/${recordId}`)
    },
    [push]
  )
  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm('roles.title')}
      extra={
        allow('can_create_role') && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleClickAdd}
          >
            {fm('button.add')}
          </Button>
        )
      }
    >
      <Card size="small">
        <Table
          onRow={(record) => {
            return {
              onClick: () => handleClickRow(record.id)
            }
          }}
          dataSource={entries}
          columns={columns}
          size="small"
          loading={isLoading}
          rowKey="id"
        />
      </Card>
    </PageHeader>
  )
}
