import { Form, Input } from 'antd'
import { useSelector } from 'react-redux'

import { fm } from '../../../../lang'

const { TextArea } = Input

export const TranslationFields = ({ namespace, locale }) => {
  const availableTemplates = useSelector((state) => state.templates.entries)

  return (
    <>
      <Form.Item shouldUpdate>
        {(form) => {
          const templateId = form.getFieldValue('templateId')
          const kind = form.getFieldValue('kind')
          const currentTemplate = availableTemplates.find((el) => el.id === templateId)
          const allowSlug = kind === 'collection' ||
            kind === 'custom' ||
            (kind === 'system' && currentTemplate && currentTemplate.showUrl)

          return (
            <Form.Item
              label={fm('label.translations.slug')}
              name={[namespace, 'slug']}
              required={locale === 'en' && allowSlug}
            >
              <Input disabled={!allowSlug} />
            </Form.Item>
          )
        }}
      </Form.Item>
      <Form.Item
        label={fm('label.translations.title')}
        name={[namespace, 'title']}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={fm('label.translations.seo-title')}
        name={[namespace, 'seoTitle']}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={fm('label.translations.seo-keywords')}
        name={[namespace, 'seoKeywords']}
      >
        <TextArea rows={2} />
      </Form.Item>
      <Form.Item
        label={fm('label.translations.seo-description')}
        name={[namespace, 'seoDescription']}
      >
        <TextArea rows={2} />
      </Form.Item>
      <Form.Item
        label={fm('label.translations.social-title')}
        name={[namespace, 'socialTitle']}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={fm('label.translations.social-description')}
        name={[namespace, 'socialDescription']}
      >
        <TextArea rows={2} />
      </Form.Item>
    </>
  )
}
