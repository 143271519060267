import { useCallback, useLayoutEffect, useEffect } from 'react'
import { Row, Col, Form, PageHeader, Tabs } from 'antd'
import { css } from '@linaria/core'
import { useDispatch, useSelector } from 'react-redux'

import { fm } from '../../lang'
import {
  resetOrder,
  selectOrder
} from '../../data/actions/orders'
import { useRecordHook } from '../../helpers/hooks/useRecordHook'
import { usePush } from '../../data/actions/routes'
import { useBreadcrumb } from '../../helpers/hooks/useBreadcrumb'
import { OrderItemsForm } from './Form/OrderItemsForm'
import { getObjectListProducts } from '../../data/selectors/products'
import { capitalize } from '../../helpers/string'
import { useSubscription } from '@logux/redux'
import { BasicInfo } from './Form/BasicInfo'
import { EmailStats } from './Form/EmailStats'
import { useAllow } from '../../helpers/hooks/usePermissions'

const formClass = css`
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

export const OrderForm = ({ path }) => {
  const breadcrumb = useBreadcrumb(path)
  const push = usePush()
  const { newRecord, params } = useRecordHook()
  const { id } = params
  const { entry } = useSelector((state) => state.orders)
  const { isSelected } = entry
  const products = useSelector(getObjectListProducts)
  const shops = useSelector((state) => state.shops.entries)
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const isLoading = useSubscription([`orders/${id || 'new'}`])
  const loading = !newRecord && !isSelected && isLoading
  const allow = useAllow()
  const handleBack = useCallback(() => {
    push('/orders')
  }, [push])
  useLayoutEffect(() => {
    if (!loading) {
      if (entry.notFound) {
        handleBack()
      } else {
        form.setFieldsValue(entry)
      }
    }
  }, [entry, loading])
  useEffect(() => {
    if (newRecord) {
      resetOrder(dispatch)
    }
  }, [loading, dispatch, selectOrder, resetOrder])
  const updateItemsPrice = (shopId) => {
    const items = form.getFieldValue('items')
    const shop = shops.find((el) => el.id === shopId)
    const result = items.map((item) => {
      const price =
        shop && shop.currency
          ? products[item.productId][`price${capitalize(shop.currency)}`]
          : 0
      return {
        ...item,
        price
      }
    })
    form.setFieldsValue({ items: result })
  }
  const cardTitle = newRecord ? 'new' : 'edit'
  const onValuesChange = (values) => {
    if (values.shopId) {
      updateItemsPrice(values.shopId)
    }
  }
  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm(`order.form.${cardTitle}`)}
      onBack={handleBack}
    >
      <Row>
        <Col xs={24}>
          <Row gutter={[0, 16]}>
            <Col xs={24}>
              <Tabs
                defaultActiveKey="info"
                items={[{
                  key: 'info',
                  label: 'Info',
                  children: (
                    <Form
                      initialValues={{ items: [] }}
                      form={form}
                      layout="vertical"
                      className={formClass}
                      onValuesChange={onValuesChange}
                    >
                      <Row gutter={[16, 16]}>
                        <BasicInfo loading={loading} />
                        <Col xs={24}>
                          <Form.Item shouldUpdate style={{ width: '100%' }}>
                            {(f) => {
                              return (
                                <OrderItemsForm
                                  items={f.getFieldValue('items')}
                                  isLoading={loading}
                                  details={entry.details}
                                  giftMessage={entry.giftMessage}
                                />
                              )
                            }}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  )
                }, ...allow('can_show_email_order')
                  ? [{
                      key: 'emails',
                      label: 'Emails',
                      children: <EmailStats />
                    }]
                  : []]}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </PageHeader>
  )
}
