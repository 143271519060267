import { Switch } from 'react-router-dom'
import { useSubscription } from '@logux/redux'

import { PrivateRoute } from '../PrivateRoute'
import { useAllow } from '../../helpers/hooks/usePermissions'
import { ProductsScreen } from '../../screens/Products/ProductsScreen'
import { ProductFormScreen } from '../../screens/Products/ProductFormScreen'

export const ProductsRoute = ({ isAuthenticated }) => {
  const isLoading = useSubscription([
    'products',
    'product_colors',
    'countries',
    'sage_warehouses',
    'categories',
    'specifications',
    'specification_groups',
    'media_groups'
  ])
  const allow = useAllow()
  return (
    <Switch>
      <PrivateRoute
        allow={allow('can_create_product')}
        path="/assortment/products/new"
        exact
        isAuthenticated={isAuthenticated}
        component={ProductFormScreen}
      />
      <PrivateRoute
        allow={allow('can_edit_product')}
        path="/assortment/products/:id"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={ProductFormScreen}
      />
      <PrivateRoute
        allow={allow('can_edit_product')}
        path="/assortment/products/:id/colors"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={ProductFormScreen}
      />
      <PrivateRoute
        allow={allow('can_edit_product')}
        path="/assortment/products/:id/colors/:colorId"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={ProductFormScreen}
      />
      <PrivateRoute
        allow={allow('can_show_products')}
        path="/assortment/products"
        exact
        isAuthenticated={isAuthenticated}
        componentProps={{ isLoading }}
        component={ProductsScreen}
      />
    </Switch>
  )
}
