import * as types from '../../constants/actionTypes/redirectsActionTypes'
import { v4 as uuidv4 } from 'uuid'

const emptyRedirect = {
  id: null,
  pattern: '',
  location: '',
  position: ''
}

const defaultState = {
  entry: { ...emptyRedirect, id: uuidv4() },
  entries: [],
  filtersEntries: {
    term: ''
  }
}

export default function redirectsReducer (state = defaultState, action) {
  switch (action.type) {
    case types.REDIRECTS_UPDATE:
      return {
        ...state,
        entries: state.entries.map((u) => {
          if (u.id === action.redirect.id) {
            return { ...action.redirect }
          } else {
            return u
          }
        })
      }
    case types.REDIRECTS_DELETE:
      return {
        ...state,
        entry: { ...emptyRedirect, id: uuidv4() },
        entries: state.entries.filter((u) => u.id !== action.redirect.id)
      }
    case types.REDIRECT_FETCH:
      return { ...state, entry: action.data }
    case types.REDIRECTS_CREATE:
      return {
        ...state,
        entries: [{ ...action.redirect }, ...state.entries]
      }
    case types.REDIRECT_SELECT:
      return { ...state, entry: state.entries.find((u) => u.id === action.id) }
    case types.REDIRECT_FORM_RESET:
      return { ...state, entry: { ...emptyRedirect, id: uuidv4() } }
    case types.REDIRECTS_FETCH:
      return { ...state, entries: action.data }
    case types.REDIRECTS_CHANGE_FILTERS: {
      const newFilters = {}
      action.values.forEach((el) => {
        newFilters[el.valueType] = el.value
      })
      return {
        ...state,
        filtersEntries: {
          ...state.filtersEntries,
          ...newFilters
        }
      }
    }
    default:
      return state
  }
}
