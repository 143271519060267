import { Card } from 'antd'
import { BasicSettings } from '../components/Account/BasicSettings'

export const AccountScreen = () => {
  return (
    <Card>
      <BasicSettings />
    </Card>
  )
}
