import { Button } from 'antd'
import { fm } from '../../../lang'

export const RemoveButton = ({ id, removeBlock }) => {
  const handleClick = () => {
    removeBlock(id)
  }
  return (
    <Button type="dashed" size="small" onClick={handleClick}>
      {fm('button.remove')}
    </Button>
  )
}
