export const setToken = (response) => {
  const tokenParams = {
    token: response.token,
    userId: response.userId,
    email: response.email,
    firstName: response.firstName,
    lastName: response.lastName
  }
  localStorage.setItem(
    `${import.meta.env.MODE}.lumie.jwt`,
    JSON.stringify(tokenParams)
  )
}

export const resetToken = () => {
  localStorage.removeItem(`${import.meta.env.MODE}.lumie.jwt`)
}

export const getToken = () => {
  const token = localStorage.getItem(`${import.meta.env.MODE}.lumie.jwt`)
  return token ? JSON.parse(token) : undefined
}
