import { TreeSelect } from 'antd'
import _ from 'lodash'
import { useSelector } from 'react-redux'

export const ProductVariantSelect = ({ ...attributes }) => {
  const productColors = useSelector((state) => state.productColors.entries)
  const products = useSelector((state) => state.products.entries)
  const shops = useSelector((state) => state.shops.entries)

  const shopIndex = _.keyBy(shops, 'id')
  const colorTitle = (colorId) => {
    const color = productColors.find(({ id }) => id === colorId)
    return color?.translations.find(({ locale }) => locale === 'en').title
  }
  const skuAndColor = (variant) => _.compact([variant.sku, colorTitle(variant.colorId)]).join(', ')
  const productVariantsTree = products.map((product) => {
    const productName = product.translations.find(({ locale }) => locale === 'en').name
    return {
      title: productName,
      value: [product.id, ..._.map(product.variants, 'id')].join(','),
      children: _.map(_.groupBy(product.variants, 'shopId'), (variants, shopId) => {
        const shopCode = shopIndex[shopId].code.toUpperCase()
        if (variants.length > 1) {
          return {
            title: `${productName} (${shopCode})`,
            value: [shopId, _.map(variants, 'id')].join(','),
            children: variants.map((variant) => ({
              title: `${productName} <${skuAndColor(variant)}> (${shopCode})`,
              value: variant.id
            }))
          }
        } else {
          return {
            title: `${productName} <${skuAndColor(variants[0])}> (${shopCode})`,
            value: variants[0].id
          }
        }
      })
    }
  })

  return (
    <TreeSelect
      placeholder="Please select product variants"
      treeCheckable
      showCheckedStrategy={TreeSelect.SHOW_PARENT}
      treeData={productVariantsTree}
      filterTreeNode={(input, treeNode) =>
        treeNode.title.toLowerCase().includes(input.toLowerCase())}
      autoClearSearchValue={false}
      {...attributes}
    />
  )
}
