import { Switch } from 'react-router-dom'
import { useSubscription } from '@logux/redux'
import { PrivateRoute } from '../PrivateRoute'
import { useAllow } from '../../helpers/hooks/usePermissions'
import { RedirectsScreen } from '../../screens/Redirects/RedirectsScreen'
import { RedirectFormScreen } from '../../screens/Redirects/RedirectFormScreen'
import { useSelector } from 'react-redux'

export const RedirectsRoute = ({ isAuthenticated }) => {
  const isLoading = useSubscription(['redirects'])
  const allow = useAllow()
  const filters = useSelector((state) => state.redirects.filtersEntries)
  const {
    term
  } = filters
  const isLoadingRedirects = useSubscription([
    {
      channel: 'redirects',
      term
    }
  ])
  return (
    <Switch>
      <PrivateRoute
        allow={allow('can_create_redirect')}
        path="/content/redirects/new"
        exact
        isAuthenticated={isAuthenticated}
        component={RedirectFormScreen}
      />
      <PrivateRoute
        allow={allow('can_edit_redirect')}
        path="/content/redirects/:id"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={RedirectFormScreen}
      />
      <PrivateRoute
        allow={allow('can_show_redirects')}
        path="/content/redirects"
        exact
        isAuthenticated={isAuthenticated}
        componentProps={{ isLoading: isLoadingRedirects }}
        component={RedirectsScreen}
      />
    </Switch>
  )
}
