import { useCallback, useState } from 'react'
import { Avatar, List, Row, Modal, Alert } from 'antd'
import { css } from '@linaria/core'
import { PlusOutlined } from '@ant-design/icons'
import { BlockPreview } from '../Blocks/BlockPreview'
import { fm } from '../../../lang'

const itemText = css`
  flex-grow: 1;
  width: 50%;
  margin-left: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const itemAvatar = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fa8c16;
  cursor: pointer;
  &:hover {
    background: #d46b08;
    opacity: 0.6;
  }
`

export const BlockItem = ({ item, addBlock }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { id } = item
  const handleClick = useCallback(() => {
    addBlock(id)
  }, [addBlock, id])
  return (
    <List.Item>
      <Row align="middle" justify="center" style={{ width: '100%' }}>
        <Avatar
          className={itemAvatar}
          icon={<PlusOutlined />}
          onClick={handleClick}
        />
        <span className={itemText}>{item.name}</span>
        <Modal
          title={false}
          centered
          open={modalOpen}
          footer={false}
          onCancel={() => setModalOpen(false)}
          width={1000}
        >
          {item.system
            ? (
              <Alert
                message={fm('alert.system-block-not-preview')}
                type="warning"
                showIcon
              />
              )
            : (
              <BlockPreview item={item} />
              )}
        </Modal>
      </Row>
    </List.Item>
  )
}
