import { Form, Input } from 'antd'

import { fm } from '../../../lang'

const { TextArea } = Input

export const TranslationFields = ({ namespace, locale }) => {
  return (
    <>
      <Form.Item
        label={fm('label.translations.title')}
        name={[namespace, 'title']}
        required={locale === 'en'}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={fm('label.translations.slug')}
        name={[namespace, 'slug']}
        required={locale === 'en'}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={fm('label.translations.theme')}
        name={[namespace, 'theme']}
        required={locale === 'en'}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={fm('label.translations.seo-title')}
        name={[namespace, 'seoTitle']}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={fm('label.translations.seo-keywords')}
        name={[namespace, 'seoKeywords']}
      >
        <TextArea rows={2} />
      </Form.Item>
      <Form.Item
        label={fm('label.translations.seo-description')}
        name={[namespace, 'seoDescription']}
      >
        <TextArea rows={2} />
      </Form.Item>
      <Form.Item
        label={fm('label.translations.social-title')}
        name={[namespace, 'socialTitle']}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={fm('label.translations.social-description')}
        name={[namespace, 'socialDescription']}
      >
        <TextArea rows={2} />
      </Form.Item>
    </>
  )
}
