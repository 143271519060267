import { useSelector } from 'react-redux'

export const WebsitesCell = ({ websiteIds, children }) => {
  const websites = useSelector((state) => state.websites.entries)

  const items = websiteIds.map((id) => {
    const website = websites.find((w) => w.id === id)
    const name = website ? website.name : '—'

    return (
      <span key={id}>
        {website && children ? children({ website, name }) : name}
      </span>
    )
  })

  if (items.length === 0) {
    return ''
  } else {
    return items.reduce((prev, curr) => [prev, ', ', curr])
  }
}
