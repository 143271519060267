import { Card, Row, Col, Select, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { getBlocksTags } from '../../../data/selectors/blocks'
import { changeBlocksTableFilters } from '../../../data/actions/blocks'
import { kinds } from '../../../data/reducers/blocksReducer'
import { fm } from '../../../lang'

const { Option } = Select

export const BlocksTableHeader = ({ updatePage }) => {
  const blockTags = useSelector(getBlocksTags)
  const filters = useSelector((state) => state.blocks.tableFilters)
  const dispatch = useDispatch()
  const tags = []
  blockTags.forEach((tag) => {
    tags.push(<Option key={tag}>{tag}</Option>)
  })
  const handleTermChange = (e) => {
    changeBlocksTableFilters(dispatch, 'term', e.target.value)
  }
  const handleKindSelect = (value) => {
    changeBlocksTableFilters(dispatch, 'kind', value)
  }
  const handleTagsSelect = (value) => {
    updatePage(1)
    changeBlocksTableFilters(dispatch, 'tags', value)
  }
  return (
    <Card size="small">
      <Row gutter={[8, 8]}>
        <Col xs={8}>
          <Input allowClear onChange={handleTermChange} defaultValue={filters.term} />
        </Col>
        <Col xs={6}>
          <Select
            allowClear
            onChange={handleKindSelect}
            style={{ width: '100%' }}
            value={filters.kind}
            placeholder="Filter by kind"
          >
            {kinds.map(kind => <Option key={kind}>{fm(`blocks.table.menu.${kind}`)}</Option>)}
          </Select>
        </Col>
        <Col xs={6}>
          <Select
            mode="multiple"
            allowClear
            onChange={handleTagsSelect}
            style={{ width: '100%' }}
            value={filters.tags}
            placeholder="Filter by tag"
          >
            {tags}
          </Select>
        </Col>
      </Row>
    </Card>
  )
}
