import { Row, Col, Select, Input, Segmented } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { fm } from '../../../lang'
import { publishedStatuses } from '../../../data/reducers/articlesReducer'
import { changeArticlesTableFilters } from '../../../data/actions/articles'

export const ArticlesTableHeader = () => {
  const dispatch = useDispatch()
  const filters = useSelector((state) => state.articles.tableFilters)
  const availableWebsites = useSelector((state) => state.websites.entries)
  const handleTermChange = (e) => {
    changeArticlesTableFilters(dispatch, 'term', e.target.value)
  }
  const handleWebsiteSelect = (value) => {
    changeArticlesTableFilters(dispatch, 'website', value)
  }
  const handlePublishedStatusSelect = (value) => {
    changeArticlesTableFilters(dispatch, 'publishedStatus', value)
  }
  return (
    <Row gutter={16}>
      <Col span={6}>
        <Input allowClear onChange={handleTermChange} defaultValue={filters.term} />
      </Col>
      <Col span={4}>
        <Select
          placeholder="Filter by website"
          onChange={handleWebsiteSelect}
          style={{ width: '100%' }}
          value={filters.website}
          allowClear
        >
          {availableWebsites.map((website) => (
            <Select.Option key={website.id} value={website.id}>
              {website.name}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col>
        <Segmented
          value={filters.publishedStatus}
          onChange={handlePublishedStatusSelect}
          options={publishedStatuses.map((status) => (
            { value: status, label: fm(`articles.published-status.${status}`) }
          ))}
        />
      </Col>
    </Row>
  )
}
