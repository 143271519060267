import { make } from './ui'
export default class Tunes {
  constructor ({ api, actions, onChange }) {
    this.api = api
    this.actions = actions
    this.onChange = onChange
    this.buttons = []
  }

  static get tunes () {
    return [
      {
        name: 'stretched',
        icon:
          '<svg width="17" height="10" viewBox="0 0 17 10" xmlns="http://www.w3.org/2000/svg"><path d="M13.568 5.925H4.056l1.703 1.703a1.125 1.125 0 0 1-1.59 1.591L.962 6.014A1.069 1.069 0 0 1 .588 4.26L4.38.469a1.069 1.069 0 0 1 1.512 1.511L4.084 3.787h9.606l-1.85-1.85a1.069 1.069 0 1 1 1.512-1.51l3.792 3.791a1.069 1.069 0 0 1-.475 1.788L13.514 9.16a1.125 1.125 0 0 1-1.59-1.591l1.644-1.644z"/></svg>',
        title: 'Stretch image'
      }
    ]
  }

  get CSS () {
    return {
      wrapper: '',
      buttonBase: this.api.styles.settingsButton,
      button: 'product-basic-image__tune',
      buttonActive: this.api.styles.settingsButtonActive
    }
  }

  render (toolData) {
    const wrapper = make('div', this.CSS.wrapper)

    this.buttons = []

    const tunes = Tunes.tunes.concat(this.actions)

    tunes.forEach((tune) => {
      const title = this.api.i18n.t(tune.title)
      const el = make('div', [this.CSS.buttonBase, this.CSS.button], {
        innerHTML: tune.icon,
        title
      })

      el.addEventListener('click', () => {
        this.tuneClicked(tune.name, tune.action)
      })

      el.dataset.tune = tune.name
      el.classList.toggle(this.CSS.buttonActive, toolData[tune.name])

      this.buttons.push(el)

      this.api.tooltip.onHover(el, title, {
        placement: 'top'
      })

      wrapper.appendChild(el)
    })

    return wrapper
  }

  tuneClicked (tuneName, customFunction) {
    if (typeof customFunction === 'function') {
      if (!customFunction(tuneName)) {
        return false
      }
    }

    const button = this.buttons.find((el) => el.dataset.tune === tuneName)

    button.classList.toggle(
      this.CSS.buttonActive,
      !button.classList.contains(this.CSS.buttonActive)
    )

    this.onChange(tuneName)
  }
}
