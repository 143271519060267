import { Switch } from 'react-router-dom'
import { PrivateRoute } from '../PrivateRoute'
import { VouchersScreen } from '../../screens/Vouchers/VouchersScreen'
import { VoucherFormScreen } from '../../screens/Vouchers/VoucherFormScreen'
import { useSubscription } from '@logux/redux'
import { useAllow } from '../../helpers/hooks/usePermissions'

export const VouchersRoute = ({ isAuthenticated }) => {
  const isLoading = useSubscription(['vouchers', 'products', 'shops'])
  const allow = useAllow()
  return (
    <Switch>
      <PrivateRoute
        allow={allow('can_create_voucher')}
        path="/vouchers/new"
        exact
        isAuthenticated={isAuthenticated}
        component={VoucherFormScreen}
      />
      <PrivateRoute
        allow={allow('can_edit_voucher')}
        path="/vouchers/:id"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={VoucherFormScreen}
      />
      <PrivateRoute
        allow={allow('can_show_vouchers')}
        path="/vouchers"
        exact
        isAuthenticated={isAuthenticated}
        component={() => <VouchersScreen isLoading={isLoading} />}
      />
    </Switch>
  )
}
