import * as types from '../../constants/actionTypes/customersActionTypes'
import { v4 as uuidv4 } from 'uuid'

const emptyCustomer = {
  id: null,
  fullName: '',
  phone: '',
  agreeSubscribe: false,
  agreeGdpr: false,
  deliveryAddresses: [],
  paymentAddresses: []
}

const defaultState = {
  entry: { ...emptyCustomer, id: uuidv4() },
  entries: [],
  searchEntries: { orders: [] },
  filtersEntries: {
    term: '',
    pagination: { current: 1, pageSize: 10, totalCount: 0, totalPage: 0 }
  }
}

export default function customersReducer (state = defaultState, action) {
  switch (action.type) {
    case types.CUSTOMERS_UPDATE:
      return {
        ...state,
        entries: state.entries.map((u) => {
          if (u.id === action.customer.id) {
            return { ...action.customer }
          } else {
            return u
          }
        })
      }
    case types.CUSTOMERS_DELETE:
      return {
        ...state,
        entry: { ...emptyCustomer, id: uuidv4() },
        entries: state.entries.filter((u) => u.id !== action.customer.id)
      }
    case types.CUSTOMER_FETCH:
      return {
        ...state,
        entry: state.entry.isSelected ? state.entry : action.data
      }
    case types.CUSTOMERS_CREATE:
      return {
        ...state,
        entries: [{ ...action.customer }, ...state.entries]
      }
    case types.CUSTOMER_SELECT:
      return {
        ...state,
        entry: {
          ...state.entries.find((u) => u.id === action.id),
          isSelected: true
        }
      }
    case types.CUSTOMER_FORM_RESET:
      return { ...state, entry: { ...emptyCustomer, id: uuidv4() } }
    case types.CUSTOMERS_FETCH:
      return {
        ...state,
        entries: action.data.records,
        filtersEntries: {
          ...state.filtersEntries,
          pagination: {
            ...state.filtersEntries.pagination,
            totalCount: action.data.totalCount,
            totalPage: action.data.totalPage
          }
        }
      }
    case types.CUSTOMERS_CHANGE_FILTERS: {
      return {
        ...state,
        filtersEntries: {
          ...state.filtersEntries,
          [action.valueType]: action.value
        }
      }
    }
    case types.CUSTOMER_NOT_FOUND:
      return {
        ...state,
        entry: { ...emptyCustomer, id: uuidv4(), notFound: true }
      }
    default:
      return state
  }
}
