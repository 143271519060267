import { useCallback, useEffect, useState } from 'react'
import { Card, Table, Button, PageHeader, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { fm } from '../../lang'
import { usePush } from '../../data/actions/routes'
import { useAllow } from '../../helpers/hooks/usePermissions'
import { useBreadcrumb } from '../../helpers/hooks/useBreadcrumb'
import { useLocation } from 'react-router-dom'
import { ProductsTableFilter } from './ProductsTableFilter'
import { getFilteredProducts } from '../../data/selectors/products'

export const ProductsTable = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)
  const allow = useAllow()
  const push = usePush()
  const products = useSelector(getFilteredProducts)
  const categories = useSelector((state) => state.categories.entries)
  const handleClickAdd = () => {
    push('/assortment/products/new')
  }
  const columns = [
    {
      title: fm('table.name'),
      key: 'name',
      render: (_, { publishedAt, translations }) => {
        const name = translations.find(({ locale }) => locale === 'en').name

        return (publishedAt && moment(publishedAt).isBefore(moment()))
          ? name
          : <Typography.Text type="secondary">{name}</Typography.Text>
      }
    },
    {
      title: 'Category name',
      key: 'categoryId',
      dataIndex: 'categoryId',
      render: (categoryId) => {
        const category = categories.find((el) => el.id === categoryId)
        return (
          <span>
            {category ? category.translations.find(({ locale }) => locale === 'en').title : '—'}
          </span>
        )
      }
    },
    {
      title: fm('table.published-at'),
      key: 'publishedAt',
      dataIndex: 'publishedAt',
      width: 200,
      render: (publishedAt) => publishedAt && moment(publishedAt).format('MMMM Do YYYY')
    }
  ]
  const handleClickRow = useCallback(
    (recordId) => {
      push(`/assortment/products/${recordId}`)
    },
    [push]
  )
  const [current, setCurrent] = useState(1)
  const location = useLocation()
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const page = params.get('page')
    if (page && current !== parseInt(page)) {
      setCurrent(parseInt(page))
    }
  }, [])
  const updatePage = useCallback(
    (newPage) => {
      setCurrent(newPage)
      push(`/assortment/products?page=${newPage}`)
    },
    [push, setCurrent]
  )
  const onChange = (values) => {
    const { current } = values
    updatePage(current)
  }
  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm('products.title')}
      extra={
        allow('can_create_product') && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleClickAdd}
          >
            {fm('button.add')}
          </Button>
        )
      }
    >
      <Card size="small">
        <ProductsTableFilter updatePage={updatePage} />
      </Card>
      <div style={{ marginTop: 16 }} />
      <Card size="small">
        <Table
          onRow={(record) => {
            return {
              onClick: () => handleClickRow(record.id)
            }
          }}
          dataSource={products}
          columns={columns}
          size="small"
          loading={isLoading}
          rowKey="id"
          onChange={onChange}
          pagination={{ current }}
        />
      </Card>
    </PageHeader>
  )
}
